import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as EmenApi from '../apis/EmenApi.js';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Icon,
  IconButton,
  ScreenContainer,
  Touchable,
  ZStack,
  withTheme,
} from '@draftbit/ui';
import { H1, H2 } from '@expo/html-elements';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import * as Linking from 'expo-linking';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Modal,
  ScrollView,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const EmenufyScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const [selectedCategory, setSelectedCategory] = React.useState('All');
  const [selecteditem, setSelecteditem] = React.useState('');
  const [showOffer, setShowOffer] = React.useState(false);

  return (
    <ScreenContainer
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { flex: { minWidth: Breakpoints.Tablet, value: 1 } },
        dimensions.width
      )}
    >
      {/* Fetch User */}
      <>
        {!((props.route?.params?.username ?? null) !== null) ? null : (
          <EmenApi.FetchGetUserGET name={props.route?.params?.username ?? null}>
            {({ loading, error, data, refetchGetUser }) => {
              const fetchUserData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* Get User */}
                  <FlatList
                    contentContainerStyle={StyleSheet.applyWidth(
                      { flexShrink: 0 },
                      dimensions.width
                    )}
                    data={fetchUserData}
                    horizontal={false}
                    initialNumToRender={1}
                    keyExtractor={getUserData =>
                      getUserData?.id ||
                      getUserData?.uuid ||
                      JSON.stringify(getUserData)
                    }
                    listKey={'zKZ8UAue'}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item }) => {
                      const getUserData = item;
                      return (
                        <>
                          {/* Main View */}
                          <View
                            removeClippedSubviews={false}
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                backgroundColor: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: getUserData?.background_color,
                                  },
                                  {
                                    minWidth: Breakpoints.Tablet,
                                    value: getUserData?.background_color,
                                  },
                                ],
                                minHeight: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: dimensions.height,
                                  },
                                  {
                                    minWidth: Breakpoints.Tablet,
                                    value: dimensions.height,
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: dimensions.height,
                                  },
                                  {
                                    minWidth: Breakpoints.Desktop,
                                    value: dimensions.height,
                                  },
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: dimensions.height,
                                  },
                                ],
                              },
                              dimensions.width
                            )}
                          >
                            <Modal
                              animationType={'fade'}
                              presentationStyle={'fullScreen'}
                              transparent={true}
                              visible={showOffer === true}
                            >
                              {/* Fetch Offer */}
                              <EmenApi.FetchGetOfferGET
                                userid={getUserData?.id}
                              >
                                {({
                                  loading,
                                  error,
                                  data,
                                  refetchGetOffer,
                                }) => {
                                  const fetchOfferData = data?.json;
                                  if (loading) {
                                    return <ActivityIndicator />;
                                  }

                                  if (
                                    error ||
                                    data?.status < 200 ||
                                    data?.status >= 300
                                  ) {
                                    return <ActivityIndicator />;
                                  }

                                  return (
                                    <>
                                      {/* Get Offer */}
                                      <FlatList
                                        data={fetchOfferData}
                                        initialNumToRender={1}
                                        keyExtractor={getOfferData =>
                                          getOfferData?.id ||
                                          getOfferData?.uuid ||
                                          JSON.stringify(getOfferData)
                                        }
                                        listKey={JSON.stringify(fetchOfferData)}
                                        numColumns={1}
                                        onEndReachedThreshold={0.5}
                                        renderItem={({ item }) => {
                                          const getOfferData = item;
                                          return (
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: 'center',
                                                  backgroundColor:
                                                    'rgba(0, 0, 0, 0.4)',
                                                  height: dimensions.height,
                                                  justifyContent: 'center',
                                                  width: dimensions.width,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <BlurView
                                                intensity={10}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.BlurViewStyles(
                                                      theme
                                                    )['Blur View'],
                                                    {
                                                      alignItems: 'center',
                                                      height: '100%',
                                                      justifyContent: 'center',
                                                      width: '100%',
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                                tint={'default'}
                                              >
                                                <IconButton
                                                  color={
                                                    theme.colors[
                                                    'Custom Color_4'
                                                    ]
                                                  }
                                                  icon={'AntDesign/closecircle'}
                                                  onPress={() => {
                                                    try {
                                                      setShowOffer(false);
                                                    } catch (err) {
                                                      console.error(err);
                                                    }
                                                  }}
                                                  size={40}
                                                  style={StyleSheet.applyWidth(
                                                    { marginBottom: 16 },
                                                    dimensions.width
                                                  )}
                                                />
                                                {/* Offer Popup */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'center',
                                                      backgroundColor:
                                                        getUserData?.background_color,
                                                      borderColor:
                                                        getUserData?.accent_color,
                                                      borderRadius: 16,
                                                      borderWidth: 8,
                                                      justifyContent: 'center',
                                                      maxWidth: {
                                                        minWidth:
                                                          Breakpoints.Desktop,
                                                        value: '60%',
                                                      },
                                                      minHeight: 300,
                                                      padding: 12,
                                                      width: '80%',
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* Offer Available View */}
                                                  <>
                                                    {!(
                                                      getOfferData?.is_active ===
                                                      true
                                                    ) ? null : (
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignItems:
                                                              'center',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* Title */}
                                                        <H2
                                                          style={StyleSheet.applyWidth(
                                                            StyleSheet.compose(
                                                              GlobalStyles.H2Styles(
                                                                theme
                                                              )['H2'],
                                                              {
                                                                color:
                                                                  getUserData?.text_primary,
                                                                textAlign:
                                                                  'center',
                                                              }
                                                            ),
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {getOfferData?.title}
                                                        </H2>
                                                        {/* Description */}
                                                        <Text
                                                          accessible={true}
                                                          allowFontScaling={
                                                            true
                                                          }
                                                          style={StyleSheet.applyWidth(
                                                            StyleSheet.compose(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )['Text'],
                                                              {
                                                                color:
                                                                  getUserData?.text_primary,
                                                                fontFamily:
                                                                  'System',
                                                                fontSize: 16,
                                                                fontWeight:
                                                                  '600',
                                                                textAlign:
                                                                  'center',
                                                              }
                                                            ),
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {
                                                            getOfferData?.description
                                                          }
                                                        </Text>
                                                        {/* Offer Link Button */}
                                                        <>
                                                          {!(
                                                            getOfferData?.button_title !==
                                                            null
                                                          ) ? null : (
                                                            <Touchable
                                                              onPress={() => {
                                                                try {
                                                                  Linking.openURL(
                                                                    `${getOfferData?.button_link}`
                                                                  );
                                                                } catch (err) {
                                                                  console.error(
                                                                    err
                                                                  );
                                                                }
                                                              }}
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  marginTop: 20,
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              <View
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    alignItems:
                                                                      'center',
                                                                    backgroundColor:
                                                                      getUserData?.widget_color,
                                                                    borderRadius: 8,
                                                                    height: 52,
                                                                    justifyContent:
                                                                      'center',
                                                                    minWidth: 40,
                                                                    paddingLeft: 12,
                                                                    paddingRight: 12,
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                <Text
                                                                  accessible={
                                                                    true
                                                                  }
                                                                  allowFontScaling={
                                                                    true
                                                                  }
                                                                  style={StyleSheet.applyWidth(
                                                                    StyleSheet.compose(
                                                                      GlobalStyles.TextStyles(
                                                                        theme
                                                                      )['Text'],
                                                                      {
                                                                        color:
                                                                          getUserData?.text_secondary,
                                                                        fontFamily:
                                                                          'System',
                                                                        fontSize: 18,
                                                                        fontWeight:
                                                                          '600',
                                                                      }
                                                                    ),
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  {
                                                                    getOfferData?.button_title
                                                                  }
                                                                </Text>
                                                              </View>
                                                            </Touchable>
                                                          )}
                                                        </>
                                                      </View>
                                                    )}
                                                  </>
                                                  {/* No Offers Available */}
                                                  <>
                                                    {!(
                                                      getOfferData?.is_active !==
                                                      true
                                                    ) ? null : (
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignItems:
                                                              'center',
                                                            justifyContent:
                                                              'space-evenly',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* Title */}
                                                        <H2
                                                          style={StyleSheet.applyWidth(
                                                            StyleSheet.compose(
                                                              GlobalStyles.H2Styles(
                                                                theme
                                                              )['H2'],
                                                              {
                                                                color:
                                                                  getUserData?.text_primary,
                                                                textAlign:
                                                                  'center',
                                                              }
                                                            ),
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {
                                                            'No Offers Available'
                                                          }
                                                        </H2>
                                                        {/* Back Button */}
                                                        <Touchable
                                                          onPress={() => {
                                                            try {
                                                              setShowOffer(
                                                                false
                                                              );
                                                            } catch (err) {
                                                              console.error(
                                                                err
                                                              );
                                                            }
                                                          }}
                                                          style={StyleSheet.applyWidth(
                                                            { marginTop: 20 },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <View
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                alignItems:
                                                                  'center',
                                                                backgroundColor:
                                                                  getUserData?.widget_color,
                                                                borderRadius: 8,
                                                                height: 52,
                                                                justifyContent:
                                                                  'center',
                                                                minWidth: 40,
                                                                paddingLeft: 12,
                                                                paddingRight: 12,
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            <Text
                                                              accessible={true}
                                                              allowFontScaling={
                                                                true
                                                              }
                                                              style={StyleSheet.applyWidth(
                                                                StyleSheet.compose(
                                                                  GlobalStyles.TextStyles(
                                                                    theme
                                                                  )['Text'],
                                                                  {
                                                                    color:
                                                                      getUserData?.text_secondary,
                                                                    fontFamily:
                                                                      'System',
                                                                    fontSize: 18,
                                                                    fontWeight:
                                                                      '600',
                                                                  }
                                                                ),
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {'Go Back'}
                                                            </Text>
                                                          </View>
                                                        </Touchable>
                                                      </View>
                                                    )}
                                                  </>
                                                </View>
                                              </BlurView>
                                            </View>
                                          );
                                        }}
                                        showsHorizontalScrollIndicator={false}
                                        showsVerticalScrollIndicator={false}
                                        style={StyleSheet.applyWidth(
                                          {
                                            height: dimensions.height,
                                            width: dimensions.width,
                                          },
                                          dimensions.width
                                        )}
                                      />
                                    </>
                                  );
                                }}
                              </EmenApi.FetchGetOfferGET>
                            </Modal>
                            {/* Max W View */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  width: '100%', // Set width to 100% for phones
                                  maxWidth: [
                                    { minWidth: Breakpoints.Tablet, value: '80%' },
                                    { minWidth: Breakpoints.Desktop, value: '70%' },
                                    { minWidth: Breakpoints.BigScreen, value: '60%' },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              {/* Banner & Logo */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { padding: 8 },
                                  dimensions.width
                                )}
                              >
                                {/* Banner Stack */}
                                <ZStack
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ZStackStyles(theme)[
                                      'Z Stack'
                                      ],
                                      {
                                        alignItems: 'flex-start',
                                        height: 120,
                                        justifyContent: 'flex-end',
                                        width: '100%',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {/* Banner Image */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { height: 120, width: '100%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Watermark */}
                                    <>
                                      {!(
                                        getUserData?.sub_status !== 'active' && getUserData?.sub_status !== 'trialing'
                                      ) ? null : (
                                        <Touchable
                                          onPress={() => {
                                            try {
                                              Linking.openURL(
                                                'https://emenufy.in'
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          style={StyleSheet.applyWidth(
                                            { height: 120, width: '100%' },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Watermark Banner */}
                                          <Image
                                            resizeMode={'cover'}
                                            source={Images.PoweredByEmenufy}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ImageStyles(theme)[
                                                'Image'
                                                ],
                                                {
                                                  borderRadius: 8,
                                                  height: 120,
                                                  width: '100%',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          />
                                        </Touchable>
                                      )}
                                    </>
                                    {/* Banner */}
                                    <>
                                      {!(
                                        getUserData?.sub_status === 'active' || getUserData?.sub_status === 'trialing'
                                      ) ? null : (
                                        <Image
                                          resizeMode={'cover'}
                                          source={{
                                            uri: `${getUserData?.banner}`,
                                          }}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.ImageStyles(theme)[
                                              'Image'
                                              ],
                                              {
                                                borderRadius: 8,
                                                height: 120,
                                                width: '100%',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        />
                                      )}
                                    </>
                                  </View>
                                  {/* Offer Button */}
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        setShowOffer(true);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          backgroundColor:
                                            getUserData?.background_color,
                                          borderColor:
                                            getUserData?.accent_color,
                                          borderRadius: 8,
                                          borderWidth: 2,
                                          height: 28,
                                          justifyContent: 'center',
                                          margin: 4,
                                          width: 72,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        allowFontScaling={true}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                            'Text'
                                            ],
                                            {
                                              color: getUserData?.text_primary,
                                              fontFamily: 'System',
                                              fontSize: 16,
                                              fontWeight: '700',
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'OFFER'}
                                      </Text>
                                    </View>
                                  </Touchable>
                                </ZStack>

                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                      height: 120,
                                      marginTop: -60,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Logo */}
                                  <Image
                                    resizeMode={'cover'}
                                    source={{ uri: `${getUserData?.logo}` }}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ImageStyles(theme)[
                                        'Image'
                                        ],
                                        {
                                          borderColor: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 'rgb(255, 255, 255)',
                                            },
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value:
                                                getUserData?.background_color,
                                            },
                                          ],
                                          borderRadius: 100,
                                          borderWidth: 5,
                                          height: 120,
                                          marginLeft: 20,
                                          width: 120,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  />
                                  {/* Display Name */}
                                  <>
                                    {!getUserData?.display_name ? null : (
                                      <H1
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.H1Styles(theme)['H1'],
                                            {
                                              color: getUserData?.text_primary,
                                              fontSize: 24,
                                              lineHeight: 32,
                                              marginLeft: -2,
                                              marginTop: 80,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {getUserData?.display_name}
                                      </H1>
                                    )}
                                  </>
                                  {/* VarifiedBadge */}
                                  <>
                                    {!(
                                      getUserData?.badge === 'Varified'
                                    ) ? null : (
                                      <Icon
                                        color={theme.colors['Custom Color']}
                                        name={'MaterialIcons/verified'}
                                        size={20}
                                        style={StyleSheet.applyWidth(
                                          { marginLeft: 1, marginTop: 55 },
                                          dimensions.width
                                        )}
                                      />
                                    )}
                                  </>
                                  {/* StarBadge */}
                                  <>
                                    {!(getUserData?.badge === 'Star') ? null : (
                                      <Icon
                                        color={theme.colors['Custom Color_2']}
                                        name={
                                          'MaterialCommunityIcons/star-circle'
                                        }
                                        size={20}
                                        style={StyleSheet.applyWidth(
                                          { marginLeft: 1, marginTop: 55 },
                                          dimensions.width
                                        )}
                                      />
                                    )}
                                  </>
                                  {/* HeartBadge */}
                                  <>
                                    {!(
                                      getUserData?.badge === 'Heart'
                                    ) ? null : (
                                      <Icon
                                        color={theme.colors['Custom Color_3']}
                                        name={'Ionicons/heart-sharp'}
                                        size={20}
                                        style={StyleSheet.applyWidth(
                                          { marginLeft: 1, marginTop: 55 },
                                          dimensions.width
                                        )}
                                      />
                                    )}
                                  </>
                                </View>
                              </View>
                              {/* Fetch Social Links */}
                              <>
                                {!(
                                  getUserData?.sub_status === 'active' || getUserData?.sub_status === 'trialing'
                                ) ? null : (
                                  <EmenApi.FetchGetSocialLinksGET
                                    userid={getUserData?.id}
                                  >
                                    {({
                                      loading,
                                      error,
                                      data,
                                      refetchGetSocialLinks,
                                    }) => {
                                      const fetchSocialLinksData = data?.json;
                                      if (loading) {
                                        return <ActivityIndicator />;
                                      }

                                      if (
                                        error ||
                                        data?.status < 200 ||
                                        data?.status >= 300
                                      ) {
                                        return <ActivityIndicator />;
                                      }

                                      return (
                                        <>
                                          {/* Social Link List */}
                                          <FlatList
                                            data={fetchSocialLinksData}
                                            keyExtractor={socialLinkListData =>
                                              socialLinkListData?.id ||
                                              socialLinkListData?.uuid ||
                                              JSON.stringify(socialLinkListData)
                                            }
                                            listKey={JSON.stringify(
                                              fetchSocialLinksData
                                            )}
                                            numColumns={1}
                                            onEndReachedThreshold={0.5}
                                            renderItem={({ item }) => {
                                              const socialLinkListData = item;
                                              return (
                                                <>
                                                  {/* Social Icons */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: 'center',
                                                        flexDirection: 'row',
                                                        flexWrap: 'wrap',
                                                        justifyContent:
                                                          'center',
                                                        marginBottom: 6,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {/* Location */}
                                                    <>
                                                      {!(
                                                        socialLinkListData?.location !==
                                                        null
                                                      ) ? null : (
                                                        <Touchable
                                                          onPress={() => {
                                                            try {
                                                              Linking.openURL(
                                                                `${socialLinkListData?.location}`
                                                              );
                                                            } catch (err) {
                                                              console.error(
                                                                err
                                                              );
                                                            }
                                                          }}
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              marginBottom: 4,
                                                              marginRight: 4,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {/* location */}
                                                          <Image
                                                            resizeMode={'cover'}
                                                            source={
                                                              Images.Location
                                                            }
                                                            style={StyleSheet.applyWidth(
                                                              StyleSheet.compose(
                                                                GlobalStyles.ImageStyles(
                                                                  theme
                                                                )['Image'],
                                                                {
                                                                  borderColor:
                                                                    'rgba(66, 14, 14, 0)',
                                                                  borderRadius: 100,
                                                                  borderWidth: 2,
                                                                  height: 34,
                                                                  width: 34,
                                                                }
                                                              ),
                                                              dimensions.width
                                                            )}
                                                          />
                                                        </Touchable>
                                                      )}
                                                    </>
                                                    {/* Phone No */}
                                                    <>
                                                      {!(
                                                        socialLinkListData?.phone_no !==
                                                        null
                                                      ) ? null : (
                                                        <Touchable
                                                          onPress={() => {
                                                            try {
                                                              Linking.openURL(
                                                                `tel:${socialLinkListData?.phone_no}`
                                                              );
                                                            } catch (err) {
                                                              console.error(
                                                                err
                                                              );
                                                            }
                                                          }}
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              marginBottom: 4,
                                                              marginRight: 4,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {/* phone_no */}
                                                          <Image
                                                            resizeMode={'cover'}
                                                            source={
                                                              Images.PhoneNo
                                                            }
                                                            style={StyleSheet.applyWidth(
                                                              StyleSheet.compose(
                                                                GlobalStyles.ImageStyles(
                                                                  theme
                                                                )['Image'],
                                                                {
                                                                  borderColor:
                                                                    'rgba(66, 14, 14, 0)',
                                                                  borderRadius: 100,
                                                                  borderWidth: 2,
                                                                  height: 34,
                                                                  width: 34,
                                                                }
                                                              ),
                                                              dimensions.width
                                                            )}
                                                          />
                                                        </Touchable>
                                                      )}
                                                    </>
                                                    {/* Google Review */}
                                                    <>
                                                      {!(
                                                        socialLinkListData?.google_review !==
                                                        null
                                                      ) ? null : (
                                                        <Touchable
                                                          onPress={() => {
                                                            try {
                                                              Linking.openURL(
                                                                `https://search.google.com/local/writereview?placeid=${socialLinkListData?.google_review}`
                                                              );
                                                            } catch (err) {
                                                              console.error(
                                                                err
                                                              );
                                                            }
                                                          }}
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              marginBottom: 4,
                                                              marginRight: 4,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {/* google review */}
                                                          <Image
                                                            resizeMode={'cover'}
                                                            source={
                                                              Images.Google
                                                            }
                                                            style={StyleSheet.applyWidth(
                                                              StyleSheet.compose(
                                                                GlobalStyles.ImageStyles(
                                                                  theme
                                                                )['Image'],
                                                                {
                                                                  borderColor:
                                                                    'rgba(66, 14, 14, 0)',
                                                                  borderRadius: 100,
                                                                  borderWidth: 2,
                                                                  height: 34,
                                                                  width: 34,
                                                                }
                                                              ),
                                                              dimensions.width
                                                            )}
                                                          />
                                                        </Touchable>
                                                      )}
                                                    </>
                                                    {/* Whatsapp */}
                                                    <>
                                                      {!(
                                                        socialLinkListData?.WhatsApp !==
                                                        null
                                                      ) ? null : (
                                                        <Touchable
                                                          onPress={() => {
                                                            try {
                                                              Linking.openURL(
                                                                `whatsapp://send/?phone=${socialLinkListData?.WhatsApp}`
                                                              );
                                                            } catch (err) {
                                                              console.error(
                                                                err
                                                              );
                                                            }
                                                          }}
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              marginBottom: 4,
                                                              marginRight: 4,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {/* whatsapp */}
                                                          <Image
                                                            resizeMode={'cover'}
                                                            source={
                                                              Images.Whatsapp
                                                            }
                                                            style={StyleSheet.applyWidth(
                                                              StyleSheet.compose(
                                                                GlobalStyles.ImageStyles(
                                                                  theme
                                                                )['Image'],
                                                                {
                                                                  borderColor:
                                                                    'rgba(66, 14, 14, 0)',
                                                                  borderRadius: 100,
                                                                  borderWidth: 2,
                                                                  height: 34,
                                                                  width: 34,
                                                                }
                                                              ),
                                                              dimensions.width
                                                            )}
                                                          />
                                                        </Touchable>
                                                      )}
                                                    </>
                                                    {/* Insta */}
                                                    <>
                                                      {!(
                                                        socialLinkListData?.Instagram !==
                                                        null
                                                      ) ? null : (
                                                        <Touchable
                                                          onPress={() => {
                                                            try {
                                                              Linking.openURL(
                                                                `https://instagram.com/${socialLinkListData?.Instagram}`
                                                              );
                                                            } catch (err) {
                                                              console.error(
                                                                err
                                                              );
                                                            }
                                                          }}
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              marginBottom: 4,
                                                              marginRight: 4,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {/* insta */}
                                                          <Image
                                                            resizeMode={'cover'}
                                                            source={
                                                              Images.Instagram
                                                            }
                                                            style={StyleSheet.applyWidth(
                                                              StyleSheet.compose(
                                                                GlobalStyles.ImageStyles(
                                                                  theme
                                                                )['Image'],
                                                                {
                                                                  borderColor:
                                                                    'rgba(66, 14, 14, 0)',
                                                                  borderRadius: 100,
                                                                  borderWidth: 2,
                                                                  height: 34,
                                                                  width: 34,
                                                                }
                                                              ),
                                                              dimensions.width
                                                            )}
                                                          />
                                                        </Touchable>
                                                      )}
                                                    </>
                                                    {/* FB */}
                                                    <>
                                                      {!(
                                                        socialLinkListData?.Facebook !==
                                                        null
                                                      ) ? null : (
                                                        <Touchable
                                                          onPress={() => {
                                                            try {
                                                              Linking.openURL(
                                                                `https://facebook.com/${socialLinkListData?.Facebook}`
                                                              );
                                                            } catch (err) {
                                                              console.error(
                                                                err
                                                              );
                                                            }
                                                          }}
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              marginBottom: 4,
                                                              marginRight: 4,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {/* fb */}
                                                          <Image
                                                            resizeMode={'cover'}
                                                            source={
                                                              Images.Facebook
                                                            }
                                                            style={StyleSheet.applyWidth(
                                                              StyleSheet.compose(
                                                                GlobalStyles.ImageStyles(
                                                                  theme
                                                                )['Image'],
                                                                {
                                                                  borderColor:
                                                                    'rgba(66, 14, 14, 0)',
                                                                  borderRadius: 100,
                                                                  borderWidth: 2,
                                                                  height: 34,
                                                                  width: 34,
                                                                }
                                                              ),
                                                              dimensions.width
                                                            )}
                                                          />
                                                        </Touchable>
                                                      )}
                                                    </>
                                                    {/* Snapchat */}
                                                    <>
                                                      {!(
                                                        socialLinkListData?.Snapchat !==
                                                        null
                                                      ) ? null : (
                                                        <Touchable
                                                          onPress={() => {
                                                            try {
                                                              Linking.openURL(
                                                                `https://snapchat.com/${socialLinkListData?.Snapchat}`
                                                              );
                                                            } catch (err) {
                                                              console.error(
                                                                err
                                                              );
                                                            }
                                                          }}
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              marginBottom: 4,
                                                              marginRight: 4,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {/* snapchat */}
                                                          <Image
                                                            resizeMode={'cover'}
                                                            source={
                                                              Images.Snapchat
                                                            }
                                                            style={StyleSheet.applyWidth(
                                                              StyleSheet.compose(
                                                                GlobalStyles.ImageStyles(
                                                                  theme
                                                                )['Image'],
                                                                {
                                                                  borderColor:
                                                                    'rgba(66, 14, 14, 0)',
                                                                  borderRadius: 100,
                                                                  borderWidth: 2,
                                                                  height: 34,
                                                                  width: 34,
                                                                }
                                                              ),
                                                              dimensions.width
                                                            )}
                                                          />
                                                        </Touchable>
                                                      )}
                                                    </>
                                                    {/* Youtube */}
                                                    <>
                                                      {!(
                                                        socialLinkListData?.Youtube !==
                                                        null
                                                      ) ? null : (
                                                        <Touchable
                                                          onPress={() => {
                                                            try {
                                                              Linking.openURL(
                                                                `https://youtube.com/${socialLinkListData?.Youtube}`
                                                              );
                                                            } catch (err) {
                                                              console.error(
                                                                err
                                                              );
                                                            }
                                                          }}
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              marginBottom: 4,
                                                              marginRight: 4,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {/* youtube */}
                                                          <Image
                                                            resizeMode={'cover'}
                                                            source={
                                                              Images.Youtube
                                                            }
                                                            style={StyleSheet.applyWidth(
                                                              StyleSheet.compose(
                                                                GlobalStyles.ImageStyles(
                                                                  theme
                                                                )['Image'],
                                                                {
                                                                  borderColor:
                                                                    'rgba(66, 14, 14, 0)',
                                                                  borderRadius: 100,
                                                                  borderWidth: 2,
                                                                  height: 34,
                                                                  width: 34,
                                                                }
                                                              ),
                                                              dimensions.width
                                                            )}
                                                          />
                                                        </Touchable>
                                                      )}
                                                    </>
                                                    {/* TikTok */}
                                                    <>
                                                      {!(
                                                        socialLinkListData?.Tiktok !==
                                                        null
                                                      ) ? null : (
                                                        <Touchable
                                                          onPress={() => {
                                                            try {
                                                              Linking.openURL(
                                                                `https://tiktok.com/${socialLinkListData?.Tiktok}`
                                                              );
                                                            } catch (err) {
                                                              console.error(
                                                                err
                                                              );
                                                            }
                                                          }}
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              marginBottom: 4,
                                                              marginRight: 4,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {/* tiktok */}
                                                          <Image
                                                            resizeMode={'cover'}
                                                            source={
                                                              Images.Tiktok
                                                            }
                                                            style={StyleSheet.applyWidth(
                                                              StyleSheet.compose(
                                                                GlobalStyles.ImageStyles(
                                                                  theme
                                                                )['Image'],
                                                                {
                                                                  borderColor:
                                                                    'rgba(66, 14, 14, 0)',
                                                                  borderRadius: 100,
                                                                  borderWidth: 2,
                                                                  height: 34,
                                                                  width: 34,
                                                                }
                                                              ),
                                                              dimensions.width
                                                            )}
                                                          />
                                                        </Touchable>
                                                      )}
                                                    </>
                                                    {/* UberEats */}
                                                    <>
                                                      {!(
                                                        socialLinkListData?.ubereats !==
                                                        null
                                                      ) ? null : (
                                                        <Touchable
                                                          onPress={() => {
                                                            try {
                                                              Linking.openURL(
                                                                `https://ubereats.com/${socialLinkListData?.ubereats}`
                                                              );
                                                            } catch (err) {
                                                              console.error(
                                                                err
                                                              );
                                                            }
                                                          }}
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              marginBottom: 4,
                                                              marginRight: 4,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {/* ubereats */}
                                                          <Image
                                                            resizeMode={'cover'}
                                                            source={
                                                              Images.Ubereats
                                                            }
                                                            style={StyleSheet.applyWidth(
                                                              StyleSheet.compose(
                                                                GlobalStyles.ImageStyles(
                                                                  theme
                                                                )['Image'],
                                                                {
                                                                  borderColor:
                                                                    'rgba(66, 14, 14, 0)',
                                                                  borderRadius: 100,
                                                                  borderWidth: 2,
                                                                  height: 34,
                                                                  width: 34,
                                                                }
                                                              ),
                                                              dimensions.width
                                                            )}
                                                          />
                                                        </Touchable>
                                                      )}
                                                    </>
                                                    {/* Zomato */}
                                                    <>
                                                      {!(
                                                        socialLinkListData?.zomato !==
                                                        null
                                                      ) ? null : (
                                                        <Touchable
                                                          onPress={() => {
                                                            try {
                                                              Linking.openURL(
                                                                `https://zomato.com/${socialLinkListData?.zomato}`
                                                              );
                                                            } catch (err) {
                                                              console.error(
                                                                err
                                                              );
                                                            }
                                                          }}
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              marginBottom: 4,
                                                              marginRight: 4,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {/* zomato */}
                                                          <Image
                                                            resizeMode={'cover'}
                                                            source={
                                                              Images.Zomato
                                                            }
                                                            style={StyleSheet.applyWidth(
                                                              StyleSheet.compose(
                                                                GlobalStyles.ImageStyles(
                                                                  theme
                                                                )['Image'],
                                                                {
                                                                  borderColor:
                                                                    'rgba(66, 14, 14, 0)',
                                                                  borderRadius: 100,
                                                                  borderWidth: 2,
                                                                  height: 34,
                                                                  width: 34,
                                                                }
                                                              ),
                                                              dimensions.width
                                                            )}
                                                          />
                                                        </Touchable>
                                                      )}
                                                    </>
                                                    {/* Swiggy */}
                                                    <>
                                                      {!(
                                                        socialLinkListData?.swiggy !==
                                                        null
                                                      ) ? null : (
                                                        <Touchable
                                                          onPress={() => {
                                                            try {
                                                              Linking.openURL(
                                                                `https://swiggy.com/${socialLinkListData?.swiggy}`
                                                              );
                                                            } catch (err) {
                                                              console.error(
                                                                err
                                                              );
                                                            }
                                                          }}
                                                          style={StyleSheet.applyWidth(
                                                            { marginBottom: 4 },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {/* swiggy */}
                                                          <Image
                                                            resizeMode={'cover'}
                                                            source={
                                                              Images.Swiggy
                                                            }
                                                            style={StyleSheet.applyWidth(
                                                              StyleSheet.compose(
                                                                GlobalStyles.ImageStyles(
                                                                  theme
                                                                )['Image'],
                                                                {
                                                                  borderColor:
                                                                    'rgba(66, 14, 14, 0)',
                                                                  borderRadius: 100,
                                                                  borderWidth: 2,
                                                                  height: 34,
                                                                  width: 34,
                                                                }
                                                              ),
                                                              dimensions.width
                                                            )}
                                                          />
                                                        </Touchable>
                                                      )}
                                                    </>
                                                  </View>
                                                </>
                                              );
                                            }}
                                            showsHorizontalScrollIndicator={
                                              true
                                            }
                                            showsVerticalScrollIndicator={true}
                                          />
                                        </>
                                      );
                                    }}
                                  </EmenApi.FetchGetSocialLinksGET>
                                )}
                              </>
                              {/* Fetch Categories */}
                              <EmenApi.FetchGetCategoriesGET
                                userID={getUserData?.id}
                              >
                                {({
                                  loading,
                                  error,
                                  data,
                                  refetchGetCategories,
                                }) => {
                                  const fetchCategoriesData = data?.json;
                                  if (loading) {
                                    return <ActivityIndicator />;
                                  }

                                  if (
                                    error ||
                                    data?.status < 200 ||
                                    data?.status >= 300
                                  ) {
                                    return <ActivityIndicator />;
                                  }

                                  return (
                                    <ScrollView
                                      bounces={true}
                                      contentContainerStyle={StyleSheet.applyWidth(
                                        {
                                          flexDirection: 'row',
                                          marginBottom: 8,
                                          marginLeft: 8,
                                        },
                                        dimensions.width
                                      )}
                                      horizontal={true}
                                      showsHorizontalScrollIndicator={false}
                                      showsVerticalScrollIndicator={false}
                                    >
                                      {/* Category Button */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { flexDirection: 'row' },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Pressed */}
                                        <>
                                          {!(
                                            selectedCategory === 'All'
                                          ) ? null : (
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: 'center',
                                                  backgroundColor: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value:
                                                        'rgb(234, 199, 132)',
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value:
                                                        getUserData?.accent_color,
                                                    },
                                                  ],
                                                  borderRadius: 4,
                                                  height: 28,
                                                  justifyContent: 'center',
                                                  marginRight: 8,
                                                  minWidth: 40,
                                                  paddingLeft: 8,
                                                  paddingRight: 8,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <Text
                                                accessible={true}
                                                allowFontScaling={true}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'],
                                                    {
                                                      color:
                                                        getUserData?.text_primary,
                                                      fontFamily: 'System',
                                                      fontWeight: '400',
                                                      lineHeight: 14,
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {'All'}
                                              </Text>
                                            </View>
                                          )}
                                        </>
                                        <>
                                          {!(
                                            selectedCategory !== 'All'
                                          ) ? null : (
                                            <Touchable
                                              activeOpacity={100}
                                              onPress={() => {
                                                try {
                                                  setSelectedCategory('All');
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              }}
                                            >
                                              {/* Unpressed */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    backgroundColor: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value:
                                                          'rgb(0, 100, 66)',
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value:
                                                          getUserData?.widget_color,
                                                      },
                                                    ],
                                                    borderRadius: 4,
                                                    height: 28,
                                                    justifyContent: 'center',
                                                    marginRight: 8,
                                                    minWidth: 40,
                                                    paddingLeft: 8,
                                                    paddingRight: 8,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <Text
                                                  accessible={true}
                                                  allowFontScaling={true}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'],
                                                      {
                                                        color: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value:
                                                              'rgb(255, 255, 255)',
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value:
                                                              getUserData?.text_secondary,
                                                          },
                                                        ],
                                                        fontFamily: 'System',
                                                        fontWeight: '400',
                                                        lineHeight: 14,
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'All'}
                                                </Text>
                                              </View>
                                            </Touchable>
                                          )}
                                        </>
                                      </View>
                                      {/* Category List */}
                                      <FlatList
                                        contentContainerStyle={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'stretch',
                                            flexDirection: 'row',
                                          },
                                          dimensions.width
                                        )}
                                        data={fetchCategoriesData}
                                        horizontal={true}
                                        keyExtractor={categoryListData =>
                                          categoryListData?.id ||
                                          categoryListData?.uuid ||
                                          JSON.stringify(categoryListData)
                                        }
                                        listKey={JSON.stringify(
                                          fetchCategoriesData
                                        )}
                                        numColumns={1}
                                        onEndReachedThreshold={0.5}
                                        renderItem={({ item }) => {
                                          const categoryListData = item;
                                          return (
                                            <>
                                              {/* Category Button 2 */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  { flexDirection: 'row' },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Pressed */}
                                                <>
                                                  {!(
                                                    selectedCategory ===
                                                    categoryListData?.title
                                                  ) ? null : (
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          alignItems: 'center',
                                                          backgroundColor: [
                                                            {
                                                              minWidth:
                                                                Breakpoints.Mobile,
                                                              value:
                                                                'rgb(234, 199, 132)',
                                                            },
                                                            {
                                                              minWidth:
                                                                Breakpoints.Mobile,
                                                              value:
                                                                getUserData?.accent_color,
                                                            },
                                                          ],
                                                          borderRadius: 4,
                                                          height: 28,
                                                          justifyContent:
                                                            'center',
                                                          marginRight: 8,
                                                          minWidth: 40,
                                                          paddingLeft: 8,
                                                          paddingRight: 8,
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      <Text
                                                        accessible={true}
                                                        allowFontScaling={true}
                                                        style={StyleSheet.applyWidth(
                                                          StyleSheet.compose(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )['Text'],
                                                            {
                                                              color:
                                                                getUserData?.text_primary,
                                                              fontFamily:
                                                                'System',
                                                              fontWeight: '400',
                                                              lineHeight: 14,
                                                            }
                                                          ),
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {
                                                          categoryListData?.title
                                                        }
                                                      </Text>
                                                    </View>
                                                  )}
                                                </>
                                                <>
                                                  {!(
                                                    selectedCategory !==
                                                    categoryListData?.title
                                                  ) ? null : (
                                                    <Touchable
                                                      activeOpacity={100}
                                                      onPress={() => {
                                                        try {
                                                          setSelectedCategory(
                                                            categoryListData?.title
                                                          );
                                                        } catch (err) {
                                                          console.error(err);
                                                        }
                                                      }}
                                                    >
                                                      {/* Unpressed */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignItems:
                                                              'center',
                                                            backgroundColor: [
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Mobile,
                                                                value:
                                                                  'rgb(0, 100, 66)',
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Mobile,
                                                                value:
                                                                  getUserData?.widget_color,
                                                              },
                                                            ],
                                                            borderRadius: 4,
                                                            height: 28,
                                                            justifyContent:
                                                              'center',
                                                            marginRight: 8,
                                                            minWidth: 40,
                                                            paddingLeft: 8,
                                                            paddingRight: 8,
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        <Text
                                                          accessible={true}
                                                          allowFontScaling={
                                                            true
                                                          }
                                                          style={StyleSheet.applyWidth(
                                                            StyleSheet.compose(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )['Text'],
                                                              {
                                                                color: [
                                                                  {
                                                                    minWidth:
                                                                      Breakpoints.Mobile,
                                                                    value:
                                                                      'rgb(255, 255, 255)',
                                                                  },
                                                                  {
                                                                    minWidth:
                                                                      Breakpoints.Mobile,
                                                                    value:
                                                                      getUserData?.text_secondary,
                                                                  },
                                                                ],
                                                                fontFamily:
                                                                  'System',
                                                                fontWeight:
                                                                  '400',
                                                                lineHeight: 14,
                                                              }
                                                            ),
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {
                                                            categoryListData?.title
                                                          }
                                                        </Text>
                                                      </View>
                                                    </Touchable>
                                                  )}
                                                </>
                                              </View>
                                            </>
                                          );
                                        }}
                                        showsHorizontalScrollIndicator={false}
                                        showsVerticalScrollIndicator={false}
                                      />
                                    </ScrollView>
                                  );
                                }}
                              </EmenApi.FetchGetCategoriesGET>
                              {/* Items */}
                              <View>
                                {/* Fetch Items by category */}
                                <>
                                  {!(selectedCategory !== 'All') ? null : (
                                    <EmenApi.FetchGetItemsByCategoryGET
                                      category={selectedCategory}
                                      userID={getUserData?.id}
                                    >
                                      {({
                                        loading,
                                        error,
                                        data,
                                        refetchGetItemsByCategory,
                                      }) => {
                                        const fetchItemsByCategoryData =
                                          data?.json;
                                        if (loading) {
                                          return <ActivityIndicator />;
                                        }

                                        if (
                                          error ||
                                          data?.status < 200 ||
                                          data?.status >= 300
                                        ) {
                                          return <ActivityIndicator />;
                                        }

                                        return (
                                          <>
                                            {/* Item List */}
                                            <FlatList
                                              contentContainerStyle={StyleSheet.applyWidth(
                                                {
                                                  flex: {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 1,
                                                  },
                                                },
                                                dimensions.width
                                              )}
                                              data={fetchItemsByCategoryData}
                                              keyExtractor={itemListData =>
                                                itemListData?.id ||
                                                itemListData?.uuid ||
                                                JSON.stringify(itemListData)
                                              }
                                              listKey={JSON.stringify(
                                                fetchItemsByCategoryData
                                              )}
                                              onEndReachedThreshold={0.5}
                                              renderItem={({ item }) => {
                                                const itemListData = item;
                                                return (
                                                  <>
                                                    {!(
                                                      itemListData?.is_active ===
                                                      true
                                                    ) ? null : (
                                                      <Touchable
                                                        activeOpacity={100}
                                                        disabled={
                                                          itemListData?.option_a_title ===
                                                          null
                                                        }
                                                        disabledOpacity={100}
                                                        onPress={() => {
                                                          try {
                                                            if (
                                                              selecteditem ===
                                                              itemListData?.title
                                                            ) {
                                                              setSelecteditem(
                                                                fetchItemsByCategoryData
                                                              );
                                                            } else {
                                                              setSelecteditem(
                                                                itemListData?.title
                                                              );
                                                            }
                                                          } catch (err) {
                                                            console.error(err);
                                                          }
                                                        }}
                                                      >
                                                        {/* Item Widget */}
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              backgroundColor: [
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Mobile,
                                                                  value:
                                                                    'rgb(0, 100, 66)',
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Mobile,
                                                                  value:
                                                                    getUserData?.widget_color,
                                                                },
                                                              ],
                                                              borderRadius: 8,
                                                              marginBottom: 8,
                                                              marginLeft: 8,
                                                              marginRight: 8,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {/* View A */}
                                                          <View
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                flexDirection:
                                                                  'row',
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            <ZStack
                                                              style={StyleSheet.applyWidth(
                                                                StyleSheet.compose(
                                                                  GlobalStyles.ZStackStyles(
                                                                    theme
                                                                  )['Z Stack'],
                                                                  {
                                                                    height: 130,
                                                                    width: 130,
                                                                  }
                                                                ),
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {/* thumbnail */}
                                                              <Image
                                                                resizeMode={
                                                                  'cover'
                                                                }
                                                                source={{
                                                                  uri: `${itemListData?.thumbnail}`,
                                                                }}
                                                                style={StyleSheet.applyWidth(
                                                                  StyleSheet.compose(
                                                                    GlobalStyles.ImageStyles(
                                                                      theme
                                                                    )['Image'],
                                                                    {
                                                                      borderBottomLeftRadius: 8,
                                                                      borderTopLeftRadius: 8,
                                                                      height:
                                                                        '100%',
                                                                      width:
                                                                        '100%',
                                                                    }
                                                                  ),
                                                                  dimensions.width
                                                                )}
                                                              />
                                                              {/* tags */}
                                                              <View
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    height: 100,
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {/* Bestseller */}
                                                                <>
                                                                  {!(
                                                                    itemListData?.tag ===
                                                                    'Bestseller'
                                                                  ) ? null : (
                                                                    <View
                                                                      style={StyleSheet.applyWidth(
                                                                        {
                                                                          backgroundColor:
                                                                            'rgb(245, 87, 3)',
                                                                          borderRadius: 2,
                                                                          marginLeft: 4,
                                                                          marginTop: 4,
                                                                        },
                                                                        dimensions.width
                                                                      )}
                                                                    >
                                                                      <Text
                                                                        accessible={
                                                                          true
                                                                        }
                                                                        allowFontScaling={
                                                                          true
                                                                        }
                                                                        style={StyleSheet.applyWidth(
                                                                          StyleSheet.compose(
                                                                            GlobalStyles.TextStyles(
                                                                              theme
                                                                            )[
                                                                            'Text'
                                                                            ],
                                                                            {
                                                                              color:
                                                                                'rgb(255, 255, 255)',
                                                                              fontFamily:
                                                                                'System',
                                                                              fontSize: 11,
                                                                              fontWeight:
                                                                                '600',
                                                                              lineHeight: 12,
                                                                              paddingBottom: 2,
                                                                              paddingLeft: 4,
                                                                              paddingRight: 4,
                                                                              paddingTop: 1,
                                                                              textAlign:
                                                                                'center',
                                                                            }
                                                                          ),
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        {
                                                                          'Bestseller'
                                                                        }
                                                                      </Text>
                                                                    </View>
                                                                  )}
                                                                </>
                                                                {/* New Arrival */}
                                                                <>
                                                                  {!(
                                                                    itemListData?.tag ===
                                                                    'New Arrival'
                                                                  ) ? null : (
                                                                    <View
                                                                      style={StyleSheet.applyWidth(
                                                                        {
                                                                          backgroundColor:
                                                                            'rgb(233, 68, 32)',
                                                                          borderRadius: 2,
                                                                          marginLeft: 4,
                                                                          marginTop: 4,
                                                                        },
                                                                        dimensions.width
                                                                      )}
                                                                    >
                                                                      <Text
                                                                        accessible={
                                                                          true
                                                                        }
                                                                        allowFontScaling={
                                                                          true
                                                                        }
                                                                        style={StyleSheet.applyWidth(
                                                                          StyleSheet.compose(
                                                                            GlobalStyles.TextStyles(
                                                                              theme
                                                                            )[
                                                                            'Text'
                                                                            ],
                                                                            {
                                                                              color:
                                                                                'rgb(255, 255, 255)',
                                                                              fontFamily:
                                                                                'System',
                                                                              fontSize: 11,
                                                                              fontWeight:
                                                                                '600',
                                                                              lineHeight: 12,
                                                                              paddingBottom: 2,
                                                                              paddingLeft: 4,
                                                                              paddingRight: 4,
                                                                              paddingTop: 1,
                                                                              textAlign:
                                                                                'center',
                                                                            }
                                                                          ),
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        {
                                                                          'New Arrival'
                                                                        }
                                                                      </Text>
                                                                    </View>
                                                                  )}
                                                                </>
                                                                {/* Must Try */}
                                                                <>
                                                                  {!(
                                                                    itemListData?.tag ===
                                                                    'Must Try'
                                                                  ) ? null : (
                                                                    <View
                                                                      style={StyleSheet.applyWidth(
                                                                        {
                                                                          backgroundColor:
                                                                            'rgb(62, 104, 255)',
                                                                          borderRadius: 2,
                                                                          marginLeft: 4,
                                                                          marginTop: 4,
                                                                        },
                                                                        dimensions.width
                                                                      )}
                                                                    >
                                                                      <Text
                                                                        accessible={
                                                                          true
                                                                        }
                                                                        allowFontScaling={
                                                                          true
                                                                        }
                                                                        style={StyleSheet.applyWidth(
                                                                          StyleSheet.compose(
                                                                            GlobalStyles.TextStyles(
                                                                              theme
                                                                            )[
                                                                            'Text'
                                                                            ],
                                                                            {
                                                                              color:
                                                                                'rgb(255, 255, 255)',
                                                                              fontFamily:
                                                                                'System',
                                                                              fontSize: 11,
                                                                              fontWeight:
                                                                                '600',
                                                                              lineHeight: 12,
                                                                              paddingBottom: 2,
                                                                              paddingLeft: 4,
                                                                              paddingRight: 4,
                                                                              paddingTop: 1,
                                                                              textAlign:
                                                                                'center',
                                                                            }
                                                                          ),
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        {
                                                                          'Must Try'
                                                                        }
                                                                      </Text>
                                                                    </View>
                                                                  )}
                                                                </>
                                                              </View>
                                                            </ZStack>
                                                            {/* Texts */}
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignSelf:
                                                                    'stretch',
                                                                  flex: 1,
                                                                  flexDirection:
                                                                    'column',
                                                                  justifyContent:
                                                                    'space-between',
                                                                  padding: 4,
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {/* Title n Stuff */}
                                                              <View
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    flexDirection:
                                                                      'column',
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {/* Title */}
                                                                <Text
                                                                  accessible={
                                                                    true
                                                                  }
                                                                  allowFontScaling={
                                                                    true
                                                                  }
                                                                  style={StyleSheet.applyWidth(
                                                                    StyleSheet.compose(
                                                                      GlobalStyles.TextStyles(
                                                                        theme
                                                                      )['Text'],
                                                                      {
                                                                        color: [
                                                                          {
                                                                            minWidth:
                                                                              Breakpoints.Mobile,
                                                                            value:
                                                                              'rgb(255, 255, 255)',
                                                                          },
                                                                          {
                                                                            minWidth:
                                                                              Breakpoints.Mobile,
                                                                            value:
                                                                              getUserData?.text_secondary,
                                                                          },
                                                                        ],
                                                                        fontFamily:
                                                                          'System',
                                                                        fontSize: 14,
                                                                        fontWeight:
                                                                          '600',
                                                                        lineHeight: 18,
                                                                        marginBottom: 8,
                                                                      }
                                                                    ),
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  {
                                                                    itemListData?.title
                                                                  }
                                                                </Text>

                                                                <View
                                                                  style={StyleSheet.applyWidth(
                                                                    {
                                                                      flexDirection:
                                                                        'row',
                                                                      height: 16,
                                                                      marginBottom: 8,
                                                                    },
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  {/* Options Available */}
                                                                  <>
                                                                    {!(
                                                                      itemListData?.option_a_title !==
                                                                      null
                                                                    ) ? null : (
                                                                      <Text
                                                                        accessible={
                                                                          true
                                                                        }
                                                                        allowFontScaling={
                                                                          true
                                                                        }
                                                                        style={StyleSheet.applyWidth(
                                                                          StyleSheet.compose(
                                                                            GlobalStyles.TextStyles(
                                                                              theme
                                                                            )[
                                                                            'Text'
                                                                            ],
                                                                            {
                                                                              color:
                                                                                [
                                                                                  {
                                                                                    minWidth:
                                                                                      Breakpoints.Mobile,
                                                                                    value:
                                                                                      'rgb(234, 199, 132)',
                                                                                  },
                                                                                  {
                                                                                    minWidth:
                                                                                      Breakpoints.Mobile,
                                                                                    value:
                                                                                      getUserData?.accent_color,
                                                                                  },
                                                                                ],
                                                                              fontSize: 12,
                                                                            }
                                                                          ),
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        {
                                                                          'Options Available'
                                                                        }
                                                                      </Text>
                                                                    )}
                                                                  </>
                                                                  {/* No option Available 2 */}
                                                                  <>
                                                                    {!(
                                                                      itemListData?.option_a_title ===
                                                                      null
                                                                    ) ? null : (
                                                                      <Text
                                                                        accessible={
                                                                          true
                                                                        }
                                                                        allowFontScaling={
                                                                          true
                                                                        }
                                                                        style={StyleSheet.applyWidth(
                                                                          StyleSheet.compose(
                                                                            GlobalStyles.TextStyles(
                                                                              theme
                                                                            )[
                                                                            'Text'
                                                                            ],
                                                                            {
                                                                              color:
                                                                                [
                                                                                  {
                                                                                    minWidth:
                                                                                      Breakpoints.Mobile,
                                                                                    value:
                                                                                      'rgb(234, 199, 132)',
                                                                                  },
                                                                                  {
                                                                                    minWidth:
                                                                                      Breakpoints.Mobile,
                                                                                    value:
                                                                                      getUserData?.text_secondary,
                                                                                  },
                                                                                ],
                                                                              fontSize: 12,
                                                                              opacity: 0.8,
                                                                            }
                                                                          ),
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        {
                                                                          'No option Available'
                                                                        }
                                                                      </Text>
                                                                    )}
                                                                  </>
                                                                </View>
                                                                {/* Description */}
                                                                <Text
                                                                  accessible={
                                                                    true
                                                                  }
                                                                  allowFontScaling={
                                                                    true
                                                                  }
                                                                  style={StyleSheet.applyWidth(
                                                                    StyleSheet.compose(
                                                                      GlobalStyles.TextStyles(
                                                                        theme
                                                                      )['Text'],
                                                                      {
                                                                        color: [
                                                                          {
                                                                            minWidth:
                                                                              Breakpoints.Mobile,
                                                                            value:
                                                                              'rgb(255, 255, 255)',
                                                                          },
                                                                          {
                                                                            minWidth:
                                                                              Breakpoints.Mobile,
                                                                            value:
                                                                              getUserData?.text_secondary,
                                                                          },
                                                                        ],
                                                                        fontSize: 12,
                                                                      }
                                                                    ),
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  {
                                                                    itemListData?.description
                                                                  }
                                                                </Text>
                                                              </View>
                                                              {/* Type & Price */}
                                                              <View
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    flexDirection:
                                                                      'row',
                                                                    justifyContent:
                                                                      'space-between',
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {/* Type */}
                                                                <View
                                                                  style={StyleSheet.applyWidth(
                                                                    {
                                                                      flexDirection:
                                                                        'row',
                                                                    },
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  {/* Veg */}
                                                                  <>
                                                                    {!(
                                                                      itemListData?.dietary_type ===
                                                                      'Veg'
                                                                    ) ? null : (
                                                                      <Image
                                                                        resizeMode={
                                                                          'cover'
                                                                        }
                                                                        source={
                                                                          Images.Veg
                                                                        }
                                                                        style={StyleSheet.applyWidth(
                                                                          StyleSheet.compose(
                                                                            GlobalStyles.ImageStyles(
                                                                              theme
                                                                            )[
                                                                            'Image'
                                                                            ],
                                                                            {
                                                                              height: 24,
                                                                              width: 24,
                                                                            }
                                                                          ),
                                                                          dimensions.width
                                                                        )}
                                                                      />
                                                                    )}
                                                                  </>
                                                                  {/* Non-veg */}
                                                                  <>
                                                                    {!(
                                                                      itemListData?.dietary_type ===
                                                                      'Non-veg'
                                                                    ) ? null : (
                                                                      <Image
                                                                        resizeMode={
                                                                          'cover'
                                                                        }
                                                                        source={
                                                                          Images.NonVeg
                                                                        }
                                                                        style={StyleSheet.applyWidth(
                                                                          StyleSheet.compose(
                                                                            GlobalStyles.ImageStyles(
                                                                              theme
                                                                            )[
                                                                            'Image'
                                                                            ],
                                                                            {
                                                                              height: 24,
                                                                              width: 24,
                                                                            }
                                                                          ),
                                                                          dimensions.width
                                                                        )}
                                                                      />
                                                                    )}
                                                                  </>
                                                                  {/* Egg */}
                                                                  <>
                                                                    {!(
                                                                      itemListData?.dietary_type ===
                                                                      'Egg'
                                                                    ) ? null : (
                                                                      <Image
                                                                        resizeMode={
                                                                          'cover'
                                                                        }
                                                                        source={
                                                                          Images.Egg
                                                                        }
                                                                        style={StyleSheet.applyWidth(
                                                                          StyleSheet.compose(
                                                                            GlobalStyles.ImageStyles(
                                                                              theme
                                                                            )[
                                                                            'Image'
                                                                            ],
                                                                            {
                                                                              height: 24,
                                                                              width: 24,
                                                                            }
                                                                          ),
                                                                          dimensions.width
                                                                        )}
                                                                      />
                                                                    )}
                                                                  </>
                                                                  {/* Vegan */}
                                                                  <>
                                                                    {!(
                                                                      itemListData?.dietary_type ===
                                                                      'Vegan'
                                                                    ) ? null : (
                                                                      <Image
                                                                        resizeMode={
                                                                          'cover'
                                                                        }
                                                                        source={
                                                                          Images.Vegan
                                                                        }
                                                                        style={StyleSheet.applyWidth(
                                                                          StyleSheet.compose(
                                                                            GlobalStyles.ImageStyles(
                                                                              theme
                                                                            )[
                                                                            'Image'
                                                                            ],
                                                                            {
                                                                              height: 24,
                                                                              width: 24,
                                                                            }
                                                                          ),
                                                                          dimensions.width
                                                                        )}
                                                                      />
                                                                    )}
                                                                  </>
                                                                </View>
                                                                {/* Price */}
                                                                <View
                                                                  style={StyleSheet.applyWidth(
                                                                    {
                                                                      alignItems:
                                                                        'center',
                                                                      backgroundColor:
                                                                        [
                                                                          {
                                                                            minWidth:
                                                                              Breakpoints.Mobile,
                                                                            value:
                                                                              'rgb(234, 199, 132)',
                                                                          },
                                                                          {
                                                                            minWidth:
                                                                              Breakpoints.Mobile,
                                                                            value:
                                                                              getUserData?.accent_color,
                                                                          },
                                                                        ],
                                                                      borderRadius: 4,
                                                                      height: 24,
                                                                      justifyContent:
                                                                        'center',
                                                                    },
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  <Text
                                                                    accessible={
                                                                      true
                                                                    }
                                                                    allowFontScaling={
                                                                      true
                                                                    }
                                                                    style={StyleSheet.applyWidth(
                                                                      StyleSheet.compose(
                                                                        GlobalStyles.TextStyles(
                                                                          theme
                                                                        )[
                                                                        'Text'
                                                                        ],
                                                                        {
                                                                          color:
                                                                            getUserData?.text_primary,
                                                                          fontFamily:
                                                                            'System',
                                                                          fontWeight:
                                                                            '600',
                                                                          paddingLeft: 4,
                                                                          paddingRight: 4,
                                                                        }
                                                                      ),
                                                                      dimensions.width
                                                                    )}
                                                                  >
                                                                    {
                                                                      getUserData?.currency
                                                                    }
                                                                    {
                                                                      itemListData?.price
                                                                    }
                                                                  </Text>
                                                                </View>
                                                              </View>
                                                            </View>
                                                          </View>
                                                          {/* Options Text */}
                                                          <>
                                                            {!(
                                                              selecteditem ===
                                                              itemListData?.title
                                                            ) ? null : (
                                                              <Text
                                                                accessible={
                                                                  true
                                                                }
                                                                allowFontScaling={
                                                                  true
                                                                }
                                                                style={StyleSheet.applyWidth(
                                                                  StyleSheet.compose(
                                                                    GlobalStyles.TextStyles(
                                                                      theme
                                                                    )['Text'],
                                                                    {
                                                                      color:
                                                                        getUserData?.text_secondary,
                                                                      fontFamily:
                                                                        'System',
                                                                      fontSize: 10,
                                                                      fontWeight:
                                                                        '600',
                                                                      lineHeight: 10,
                                                                      marginBottom: 4,
                                                                      marginLeft: 4,
                                                                      marginTop: 4,
                                                                    }
                                                                  ),
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {'Options'}
                                                              </Text>
                                                            )}
                                                          </>
                                                          {/* Options */}
                                                          <>
                                                            {!(
                                                              selecteditem ===
                                                              itemListData?.title
                                                            ) ? null : (
                                                              <View
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    flexDirection:
                                                                      'row',
                                                                    height: 60,
                                                                    paddingRight: 4,
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {/* Option A */}
                                                                <>
                                                                  {!(
                                                                    itemListData?.option_a_title !==
                                                                    null
                                                                  ) ? null : (
                                                                    <View
                                                                      style={StyleSheet.applyWidth(
                                                                        {
                                                                          backgroundColor:
                                                                            [
                                                                              {
                                                                                minWidth:
                                                                                  Breakpoints.Mobile,
                                                                                value:
                                                                                  'rgb(234, 199, 132)',
                                                                              },
                                                                              {
                                                                                minWidth:
                                                                                  Breakpoints.Mobile,
                                                                                value:
                                                                                  getUserData?.accent_color,
                                                                              },
                                                                            ],
                                                                          borderRadius: 4,
                                                                          flex: 1,
                                                                          marginBottom: 4,
                                                                          marginLeft: 4,
                                                                          padding: 2,
                                                                        },
                                                                        dimensions.width
                                                                      )}
                                                                    >
                                                                      <View
                                                                        style={StyleSheet.applyWidth(
                                                                          {
                                                                            alignItems:
                                                                              'center',
                                                                            backgroundColor:
                                                                              [
                                                                                {
                                                                                  minWidth:
                                                                                    Breakpoints.Mobile,
                                                                                  value:
                                                                                    'rgb(0, 100, 66)',
                                                                                },
                                                                                {
                                                                                  minWidth:
                                                                                    Breakpoints.Mobile,
                                                                                  value:
                                                                                    getUserData?.widget_color,
                                                                                },
                                                                              ],
                                                                            borderTopLeftRadius: 2,
                                                                            borderTopRightRadius: 2,
                                                                            flex: 1,
                                                                            justifyContent:
                                                                              'center',
                                                                          },
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        <Text
                                                                          accessible={
                                                                            true
                                                                          }
                                                                          allowFontScaling={
                                                                            true
                                                                          }
                                                                          style={StyleSheet.applyWidth(
                                                                            StyleSheet.compose(
                                                                              GlobalStyles.TextStyles(
                                                                                theme
                                                                              )[
                                                                              'Text'
                                                                              ],
                                                                              {
                                                                                color:
                                                                                  [
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        'rgb(255, 255, 255)',
                                                                                    },
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        getUserData?.text_secondary,
                                                                                    },
                                                                                  ],
                                                                                fontFamily:
                                                                                  'System',
                                                                                fontWeight:
                                                                                  '600',
                                                                              }
                                                                            ),
                                                                            dimensions.width
                                                                          )}
                                                                        >
                                                                          {
                                                                            itemListData?.option_a_title
                                                                          }
                                                                        </Text>
                                                                      </View>
                                                                      {/* View 2 */}
                                                                      <View
                                                                        style={StyleSheet.applyWidth(
                                                                          {
                                                                            alignItems:
                                                                              'center',
                                                                            flex: 1,
                                                                            justifyContent:
                                                                              'center',
                                                                            paddingTop: 2,
                                                                          },
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        <Text
                                                                          accessible={
                                                                            true
                                                                          }
                                                                          allowFontScaling={
                                                                            true
                                                                          }
                                                                          style={StyleSheet.applyWidth(
                                                                            StyleSheet.compose(
                                                                              GlobalStyles.TextStyles(
                                                                                theme
                                                                              )[
                                                                              'Text'
                                                                              ],
                                                                              {
                                                                                color:
                                                                                  [
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        'rgb(0, 0, 0)',
                                                                                    },
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        getUserData?.text_primary,
                                                                                    },
                                                                                  ],
                                                                                fontFamily:
                                                                                  'System',
                                                                                fontWeight:
                                                                                  '600',
                                                                              }
                                                                            ),
                                                                            dimensions.width
                                                                          )}
                                                                        >
                                                                          {
                                                                            getUserData?.currency
                                                                          }
                                                                          {
                                                                            itemListData?.option_a_price
                                                                          }
                                                                        </Text>
                                                                      </View>
                                                                    </View>
                                                                  )}
                                                                </>
                                                                {/* Option B */}
                                                                <>
                                                                  {!(
                                                                    itemListData?.option_b_title !==
                                                                    null
                                                                  ) ? null : (
                                                                    <View
                                                                      style={StyleSheet.applyWidth(
                                                                        {
                                                                          backgroundColor:
                                                                            [
                                                                              {
                                                                                minWidth:
                                                                                  Breakpoints.Mobile,
                                                                                value:
                                                                                  'rgb(234, 199, 132)',
                                                                              },
                                                                              {
                                                                                minWidth:
                                                                                  Breakpoints.Mobile,
                                                                                value:
                                                                                  getUserData?.accent_color,
                                                                              },
                                                                            ],
                                                                          borderRadius: 4,
                                                                          flex: 1,
                                                                          marginBottom: 4,
                                                                          marginLeft: 4,
                                                                          padding: 2,
                                                                        },
                                                                        dimensions.width
                                                                      )}
                                                                    >
                                                                      <View
                                                                        style={StyleSheet.applyWidth(
                                                                          {
                                                                            alignItems:
                                                                              'center',
                                                                            backgroundColor:
                                                                              [
                                                                                {
                                                                                  minWidth:
                                                                                    Breakpoints.Mobile,
                                                                                  value:
                                                                                    'rgb(0, 100, 66)',
                                                                                },
                                                                                {
                                                                                  minWidth:
                                                                                    Breakpoints.Mobile,
                                                                                  value:
                                                                                    getUserData?.widget_color,
                                                                                },
                                                                              ],
                                                                            borderTopLeftRadius: 2,
                                                                            borderTopRightRadius: 2,
                                                                            flex: 1,
                                                                            justifyContent:
                                                                              'center',
                                                                          },
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        <Text
                                                                          accessible={
                                                                            true
                                                                          }
                                                                          allowFontScaling={
                                                                            true
                                                                          }
                                                                          style={StyleSheet.applyWidth(
                                                                            StyleSheet.compose(
                                                                              GlobalStyles.TextStyles(
                                                                                theme
                                                                              )[
                                                                              'Text'
                                                                              ],
                                                                              {
                                                                                color:
                                                                                  [
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        'rgb(255, 255, 255)',
                                                                                    },
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        getUserData?.text_secondary,
                                                                                    },
                                                                                  ],
                                                                                fontFamily:
                                                                                  'System',
                                                                                fontWeight:
                                                                                  '600',
                                                                              }
                                                                            ),
                                                                            dimensions.width
                                                                          )}
                                                                        >
                                                                          {
                                                                            itemListData?.option_b_title
                                                                          }
                                                                        </Text>
                                                                      </View>
                                                                      {/* View 2 */}
                                                                      <View
                                                                        style={StyleSheet.applyWidth(
                                                                          {
                                                                            alignItems:
                                                                              'center',
                                                                            flex: 1,
                                                                            justifyContent:
                                                                              'center',
                                                                            paddingTop: 2,
                                                                          },
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        <Text
                                                                          accessible={
                                                                            true
                                                                          }
                                                                          allowFontScaling={
                                                                            true
                                                                          }
                                                                          style={StyleSheet.applyWidth(
                                                                            StyleSheet.compose(
                                                                              GlobalStyles.TextStyles(
                                                                                theme
                                                                              )[
                                                                              'Text'
                                                                              ],
                                                                              {
                                                                                color:
                                                                                  [
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        'rgb(0, 0, 0)',
                                                                                    },
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        getUserData?.text_primary,
                                                                                    },
                                                                                  ],
                                                                                fontFamily:
                                                                                  'System',
                                                                                fontWeight:
                                                                                  '600',
                                                                              }
                                                                            ),
                                                                            dimensions.width
                                                                          )}
                                                                        >
                                                                          {
                                                                            getUserData?.currency
                                                                          }
                                                                          {
                                                                            itemListData?.option_b_price
                                                                          }
                                                                        </Text>
                                                                      </View>
                                                                    </View>
                                                                  )}
                                                                </>
                                                                {/* Option C */}
                                                                <>
                                                                  {!(
                                                                    itemListData?.option_c_title !==
                                                                    null
                                                                  ) ? null : (
                                                                    <View
                                                                      style={StyleSheet.applyWidth(
                                                                        {
                                                                          backgroundColor:
                                                                            [
                                                                              {
                                                                                minWidth:
                                                                                  Breakpoints.Mobile,
                                                                                value:
                                                                                  'rgb(234, 199, 132)',
                                                                              },
                                                                              {
                                                                                minWidth:
                                                                                  Breakpoints.Mobile,
                                                                                value:
                                                                                  getUserData?.accent_color,
                                                                              },
                                                                            ],
                                                                          borderRadius: 4,
                                                                          flex: 1,
                                                                          marginBottom: 4,
                                                                          marginLeft: 4,
                                                                          padding: 2,
                                                                        },
                                                                        dimensions.width
                                                                      )}
                                                                    >
                                                                      <View
                                                                        style={StyleSheet.applyWidth(
                                                                          {
                                                                            alignItems:
                                                                              'center',
                                                                            backgroundColor:
                                                                              [
                                                                                {
                                                                                  minWidth:
                                                                                    Breakpoints.Mobile,
                                                                                  value:
                                                                                    'rgb(0, 100, 66)',
                                                                                },
                                                                                {
                                                                                  minWidth:
                                                                                    Breakpoints.Mobile,
                                                                                  value:
                                                                                    getUserData?.widget_color,
                                                                                },
                                                                              ],
                                                                            borderTopLeftRadius: 2,
                                                                            borderTopRightRadius: 2,
                                                                            flex: 1,
                                                                            justifyContent:
                                                                              'center',
                                                                          },
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        <Text
                                                                          accessible={
                                                                            true
                                                                          }
                                                                          allowFontScaling={
                                                                            true
                                                                          }
                                                                          style={StyleSheet.applyWidth(
                                                                            StyleSheet.compose(
                                                                              GlobalStyles.TextStyles(
                                                                                theme
                                                                              )[
                                                                              'Text'
                                                                              ],
                                                                              {
                                                                                color:
                                                                                  [
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        'rgb(255, 255, 255)',
                                                                                    },
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        getUserData?.text_secondary,
                                                                                    },
                                                                                  ],
                                                                                fontFamily:
                                                                                  'System',
                                                                                fontWeight:
                                                                                  '600',
                                                                              }
                                                                            ),
                                                                            dimensions.width
                                                                          )}
                                                                        >
                                                                          {
                                                                            itemListData?.option_c_title
                                                                          }
                                                                        </Text>
                                                                      </View>
                                                                      {/* View 2 */}
                                                                      <View
                                                                        style={StyleSheet.applyWidth(
                                                                          {
                                                                            alignItems:
                                                                              'center',
                                                                            flex: 1,
                                                                            justifyContent:
                                                                              'center',
                                                                            paddingTop: 2,
                                                                          },
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        <Text
                                                                          accessible={
                                                                            true
                                                                          }
                                                                          allowFontScaling={
                                                                            true
                                                                          }
                                                                          style={StyleSheet.applyWidth(
                                                                            StyleSheet.compose(
                                                                              GlobalStyles.TextStyles(
                                                                                theme
                                                                              )[
                                                                              'Text'
                                                                              ],
                                                                              {
                                                                                color:
                                                                                  [
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        'rgb(0, 0, 0)',
                                                                                    },
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        getUserData?.text_primary,
                                                                                    },
                                                                                  ],
                                                                                fontFamily:
                                                                                  'System',
                                                                                fontWeight:
                                                                                  '600',
                                                                              }
                                                                            ),
                                                                            dimensions.width
                                                                          )}
                                                                        >
                                                                          {
                                                                            getUserData?.currency
                                                                          }
                                                                          {
                                                                            itemListData?.option_c_price
                                                                          }
                                                                        </Text>
                                                                      </View>
                                                                    </View>
                                                                  )}
                                                                </>
                                                                {/* Option D */}
                                                                <>
                                                                  {!(
                                                                    itemListData?.option_d_title !==
                                                                    null
                                                                  ) ? null : (
                                                                    <View
                                                                      style={StyleSheet.applyWidth(
                                                                        {
                                                                          backgroundColor:
                                                                            [
                                                                              {
                                                                                minWidth:
                                                                                  Breakpoints.Mobile,
                                                                                value:
                                                                                  'rgb(234, 199, 132)',
                                                                              },
                                                                              {
                                                                                minWidth:
                                                                                  Breakpoints.Mobile,
                                                                                value:
                                                                                  getUserData?.accent_color,
                                                                              },
                                                                            ],
                                                                          borderRadius: 4,
                                                                          flex: 1,
                                                                          marginBottom: 4,
                                                                          marginLeft: 4,
                                                                          padding: 2,
                                                                        },
                                                                        dimensions.width
                                                                      )}
                                                                    >
                                                                      <View
                                                                        style={StyleSheet.applyWidth(
                                                                          {
                                                                            alignItems:
                                                                              'center',
                                                                            backgroundColor:
                                                                              [
                                                                                {
                                                                                  minWidth:
                                                                                    Breakpoints.Mobile,
                                                                                  value:
                                                                                    'rgb(0, 100, 66)',
                                                                                },
                                                                                {
                                                                                  minWidth:
                                                                                    Breakpoints.Mobile,
                                                                                  value:
                                                                                    getUserData?.widget_color,
                                                                                },
                                                                              ],
                                                                            borderTopLeftRadius: 2,
                                                                            borderTopRightRadius: 2,
                                                                            flex: 1,
                                                                            justifyContent:
                                                                              'center',
                                                                          },
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        <Text
                                                                          accessible={
                                                                            true
                                                                          }
                                                                          allowFontScaling={
                                                                            true
                                                                          }
                                                                          style={StyleSheet.applyWidth(
                                                                            StyleSheet.compose(
                                                                              GlobalStyles.TextStyles(
                                                                                theme
                                                                              )[
                                                                              'Text'
                                                                              ],
                                                                              {
                                                                                color:
                                                                                  [
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        'rgb(255, 255, 255)',
                                                                                    },
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        getUserData?.text_secondary,
                                                                                    },
                                                                                  ],
                                                                                fontFamily:
                                                                                  'System',
                                                                                fontWeight:
                                                                                  '600',
                                                                              }
                                                                            ),
                                                                            dimensions.width
                                                                          )}
                                                                        >
                                                                          {
                                                                            itemListData?.option_d_title
                                                                          }
                                                                        </Text>
                                                                      </View>
                                                                      {/* View 2 */}
                                                                      <View
                                                                        style={StyleSheet.applyWidth(
                                                                          {
                                                                            alignItems:
                                                                              'center',
                                                                            flex: 1,
                                                                            justifyContent:
                                                                              'center',
                                                                            paddingTop: 2,
                                                                          },
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        <Text
                                                                          accessible={
                                                                            true
                                                                          }
                                                                          allowFontScaling={
                                                                            true
                                                                          }
                                                                          style={StyleSheet.applyWidth(
                                                                            StyleSheet.compose(
                                                                              GlobalStyles.TextStyles(
                                                                                theme
                                                                              )[
                                                                              'Text'
                                                                              ],
                                                                              {
                                                                                color:
                                                                                  [
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        'rgb(0, 0, 0)',
                                                                                    },
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        getUserData?.text_primary,
                                                                                    },
                                                                                  ],
                                                                                fontFamily:
                                                                                  'System',
                                                                                fontWeight:
                                                                                  '600',
                                                                              }
                                                                            ),
                                                                            dimensions.width
                                                                          )}
                                                                        >
                                                                          {
                                                                            getUserData?.currency
                                                                          }
                                                                          {
                                                                            itemListData?.option_d_price
                                                                          }
                                                                        </Text>
                                                                      </View>
                                                                    </View>
                                                                  )}
                                                                </>
                                                              </View>
                                                            )}
                                                          </>
                                                        </View>
                                                      </Touchable>
                                                    )}
                                                  </>
                                                );
                                              }}
                                              showsHorizontalScrollIndicator={
                                                false
                                              }
                                              showsVerticalScrollIndicator={
                                                false
                                              }
                                            />
                                          </>
                                        );
                                      }}
                                    </EmenApi.FetchGetItemsByCategoryGET>
                                  )}
                                </>
                                {/* Fetch Items */}
                                <>
                                  {!(selectedCategory === 'All') ? null : (
                                    <EmenApi.FetchGetItemsGET
                                      userID={getUserData?.id}
                                    >
                                      {({
                                        loading,
                                        error,
                                        data,
                                        refetchGetItems,
                                      }) => {
                                        const fetchItemsData = data?.json;
                                        if (loading) {
                                          return <ActivityIndicator />;
                                        }

                                        if (
                                          error ||
                                          data?.status < 200 ||
                                          data?.status >= 300
                                        ) {
                                          return <ActivityIndicator />;
                                        }

                                        return (
                                          <>
                                            {/* Item List */}
                                            <FlatList
                                              contentContainerStyle={StyleSheet.applyWidth(
                                                {
                                                  flex: {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 1,
                                                  },
                                                },
                                                dimensions.width
                                              )}
                                              data={fetchItemsData}
                                              keyExtractor={itemListData =>
                                                itemListData?.id ||
                                                itemListData?.uuid ||
                                                JSON.stringify(itemListData)
                                              }
                                              listKey={JSON.stringify(
                                                fetchItemsData
                                              )}
                                              numColumns={1}
                                              onEndReachedThreshold={0.5}
                                              renderItem={({ item }) => {
                                                const itemListData = item;
                                                return (
                                                  <>
                                                    {!(
                                                      itemListData?.is_active ===
                                                      true
                                                    ) ? null : (
                                                      <Touchable
                                                        activeOpacity={100}
                                                        disabled={
                                                          itemListData?.option_a_title ===
                                                          null
                                                        }
                                                        disabledOpacity={100}
                                                        onPress={() => {
                                                          try {
                                                            if (
                                                              selecteditem ===
                                                              itemListData?.title
                                                            ) {
                                                              setSelecteditem(
                                                                fetchItemsData
                                                              );
                                                            } else {
                                                              setSelecteditem(
                                                                itemListData?.title
                                                              );
                                                            }
                                                          } catch (err) {
                                                            console.error(err);
                                                          }
                                                        }}
                                                      >
                                                        {/* Item Widget */}
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              backgroundColor: [
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Mobile,
                                                                  value:
                                                                    'rgb(0, 100, 66)',
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Mobile,
                                                                  value:
                                                                    getUserData?.widget_color,
                                                                },
                                                              ],
                                                              borderRadius: 8,
                                                              marginBottom: 8,
                                                              marginLeft: 8,
                                                              marginRight: 8,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {/* View A */}
                                                          <View
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                flexDirection:
                                                                  'row',
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            <ZStack
                                                              style={StyleSheet.applyWidth(
                                                                StyleSheet.compose(
                                                                  GlobalStyles.ZStackStyles(
                                                                    theme
                                                                  )['Z Stack'],
                                                                  {
                                                                    height: 130,
                                                                    width: 130,
                                                                  }
                                                                ),
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {/* thumbnail */}
                                                              <Image
                                                                resizeMode={
                                                                  'cover'
                                                                }
                                                                source={{
                                                                  uri: `${itemListData?.thumbnail}`,
                                                                }}
                                                                style={StyleSheet.applyWidth(
                                                                  StyleSheet.compose(
                                                                    GlobalStyles.ImageStyles(
                                                                      theme
                                                                    )['Image'],
                                                                    {
                                                                      borderBottomLeftRadius: 8,
                                                                      borderTopLeftRadius: 8,
                                                                      height:
                                                                        '100%',
                                                                      width:
                                                                        '100%',
                                                                    }
                                                                  ),
                                                                  dimensions.width
                                                                )}
                                                              />
                                                              {/* tags */}
                                                              <View
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    height: 100,
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {/* Bestseller */}
                                                                <>
                                                                  {!(
                                                                    itemListData?.tag ===
                                                                    'Bestseller'
                                                                  ) ? null : (
                                                                    <View
                                                                      style={StyleSheet.applyWidth(
                                                                        {
                                                                          backgroundColor:
                                                                            'rgb(245, 87, 3)',
                                                                          borderRadius: 2,
                                                                          marginLeft: 4,
                                                                          marginTop: 4,
                                                                        },
                                                                        dimensions.width
                                                                      )}
                                                                    >
                                                                      <Text
                                                                        accessible={
                                                                          true
                                                                        }
                                                                        allowFontScaling={
                                                                          true
                                                                        }
                                                                        style={StyleSheet.applyWidth(
                                                                          StyleSheet.compose(
                                                                            GlobalStyles.TextStyles(
                                                                              theme
                                                                            )[
                                                                            'Text'
                                                                            ],
                                                                            {
                                                                              color:
                                                                                'rgb(255, 255, 255)',
                                                                              fontFamily:
                                                                                'System',
                                                                              fontSize: 11,
                                                                              fontWeight:
                                                                                '600',
                                                                              lineHeight: 12,
                                                                              paddingBottom: 2,
                                                                              paddingLeft: 4,
                                                                              paddingRight: 4,
                                                                              paddingTop: 1,
                                                                              textAlign:
                                                                                'center',
                                                                            }
                                                                          ),
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        {
                                                                          'Bestseller'
                                                                        }
                                                                      </Text>
                                                                    </View>
                                                                  )}
                                                                </>
                                                                {/* New Arrival */}
                                                                <>
                                                                  {!(
                                                                    itemListData?.tag ===
                                                                    'New Arrival'
                                                                  ) ? null : (
                                                                    <View
                                                                      style={StyleSheet.applyWidth(
                                                                        {
                                                                          backgroundColor:
                                                                            'rgb(233, 68, 32)',
                                                                          borderRadius: 2,
                                                                          marginLeft: 4,
                                                                          marginTop: 4,
                                                                        },
                                                                        dimensions.width
                                                                      )}
                                                                    >
                                                                      <Text
                                                                        accessible={
                                                                          true
                                                                        }
                                                                        allowFontScaling={
                                                                          true
                                                                        }
                                                                        style={StyleSheet.applyWidth(
                                                                          StyleSheet.compose(
                                                                            GlobalStyles.TextStyles(
                                                                              theme
                                                                            )[
                                                                            'Text'
                                                                            ],
                                                                            {
                                                                              color:
                                                                                'rgb(255, 255, 255)',
                                                                              fontFamily:
                                                                                'System',
                                                                              fontSize: 11,
                                                                              fontWeight:
                                                                                '600',
                                                                              lineHeight: 12,
                                                                              paddingBottom: 2,
                                                                              paddingLeft: 4,
                                                                              paddingRight: 4,
                                                                              paddingTop: 1,
                                                                              textAlign:
                                                                                'center',
                                                                            }
                                                                          ),
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        {
                                                                          'New Arrival'
                                                                        }
                                                                      </Text>
                                                                    </View>
                                                                  )}
                                                                </>
                                                                {/* Must Try */}
                                                                <>
                                                                  {!(
                                                                    itemListData?.tag ===
                                                                    'Must Try'
                                                                  ) ? null : (
                                                                    <View
                                                                      style={StyleSheet.applyWidth(
                                                                        {
                                                                          backgroundColor:
                                                                            'rgb(62, 104, 255)',
                                                                          borderRadius: 2,
                                                                          marginLeft: 4,
                                                                          marginTop: 4,
                                                                        },
                                                                        dimensions.width
                                                                      )}
                                                                    >
                                                                      <Text
                                                                        accessible={
                                                                          true
                                                                        }
                                                                        allowFontScaling={
                                                                          true
                                                                        }
                                                                        style={StyleSheet.applyWidth(
                                                                          StyleSheet.compose(
                                                                            GlobalStyles.TextStyles(
                                                                              theme
                                                                            )[
                                                                            'Text'
                                                                            ],
                                                                            {
                                                                              color:
                                                                                'rgb(255, 255, 255)',
                                                                              fontFamily:
                                                                                'System',
                                                                              fontSize: 11,
                                                                              fontWeight:
                                                                                '600',
                                                                              lineHeight: 12,
                                                                              paddingBottom: 2,
                                                                              paddingLeft: 4,
                                                                              paddingRight: 4,
                                                                              paddingTop: 1,
                                                                              textAlign:
                                                                                'center',
                                                                            }
                                                                          ),
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        {
                                                                          'Must Try'
                                                                        }
                                                                      </Text>
                                                                    </View>
                                                                  )}
                                                                </>
                                                              </View>
                                                            </ZStack>
                                                            {/* Texts */}
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignSelf:
                                                                    'stretch',
                                                                  flex: 1,
                                                                  flexDirection:
                                                                    'column',
                                                                  justifyContent:
                                                                    'space-between',
                                                                  padding: 4,
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {/* Title n Stuff */}
                                                              <View
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    flexDirection:
                                                                      'column',
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {/* Title */}
                                                                <Text
                                                                  accessible={
                                                                    true
                                                                  }
                                                                  allowFontScaling={
                                                                    true
                                                                  }
                                                                  style={StyleSheet.applyWidth(
                                                                    StyleSheet.compose(
                                                                      GlobalStyles.TextStyles(
                                                                        theme
                                                                      )['Text'],
                                                                      {
                                                                        color: [
                                                                          {
                                                                            minWidth:
                                                                              Breakpoints.Mobile,
                                                                            value:
                                                                              'rgb(255, 255, 255)',
                                                                          },
                                                                          {
                                                                            minWidth:
                                                                              Breakpoints.Mobile,
                                                                            value:
                                                                              getUserData?.text_secondary,
                                                                          },
                                                                        ],
                                                                        fontFamily:
                                                                          'System',
                                                                        fontSize: 14,
                                                                        fontWeight:
                                                                          '600',
                                                                        lineHeight: 18,
                                                                        marginBottom: 8,
                                                                      }
                                                                    ),
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  {
                                                                    itemListData?.title
                                                                  }
                                                                </Text>

                                                                <View
                                                                  style={StyleSheet.applyWidth(
                                                                    {
                                                                      flexDirection:
                                                                        'row',
                                                                      height: 16,
                                                                      marginBottom: 8,
                                                                    },
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  {/* Options Available */}
                                                                  <>
                                                                    {!(
                                                                      itemListData?.option_a_title !==
                                                                      null
                                                                    ) ? null : (
                                                                      <Text
                                                                        accessible={
                                                                          true
                                                                        }
                                                                        allowFontScaling={
                                                                          true
                                                                        }
                                                                        style={StyleSheet.applyWidth(
                                                                          StyleSheet.compose(
                                                                            GlobalStyles.TextStyles(
                                                                              theme
                                                                            )[
                                                                            'Text'
                                                                            ],
                                                                            {
                                                                              color:
                                                                                [
                                                                                  {
                                                                                    minWidth:
                                                                                      Breakpoints.Mobile,
                                                                                    value:
                                                                                      'rgb(234, 199, 132)',
                                                                                  },
                                                                                  {
                                                                                    minWidth:
                                                                                      Breakpoints.Mobile,
                                                                                    value:
                                                                                      getUserData?.accent_color,
                                                                                  },
                                                                                ],
                                                                              fontSize: 12,
                                                                            }
                                                                          ),
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        {
                                                                          'Options Available'
                                                                        }
                                                                      </Text>
                                                                    )}
                                                                  </>
                                                                  {/* No option Available 2 */}
                                                                  <>
                                                                    {!(
                                                                      itemListData?.option_a_title ===
                                                                      null
                                                                    ) ? null : (
                                                                      <Text
                                                                        accessible={
                                                                          true
                                                                        }
                                                                        allowFontScaling={
                                                                          true
                                                                        }
                                                                        style={StyleSheet.applyWidth(
                                                                          StyleSheet.compose(
                                                                            GlobalStyles.TextStyles(
                                                                              theme
                                                                            )[
                                                                            'Text'
                                                                            ],
                                                                            {
                                                                              color:
                                                                                [
                                                                                  {
                                                                                    minWidth:
                                                                                      Breakpoints.Mobile,
                                                                                    value:
                                                                                      'rgb(234, 199, 132)',
                                                                                  },
                                                                                  {
                                                                                    minWidth:
                                                                                      Breakpoints.Mobile,
                                                                                    value:
                                                                                      getUserData?.text_secondary,
                                                                                  },
                                                                                ],
                                                                              fontSize: 12,
                                                                              opacity: 0.8,
                                                                            }
                                                                          ),
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        {
                                                                          'No option Available'
                                                                        }
                                                                      </Text>
                                                                    )}
                                                                  </>
                                                                </View>
                                                                {/* Description */}
                                                                <Text
                                                                  accessible={
                                                                    true
                                                                  }
                                                                  allowFontScaling={
                                                                    true
                                                                  }
                                                                  style={StyleSheet.applyWidth(
                                                                    StyleSheet.compose(
                                                                      GlobalStyles.TextStyles(
                                                                        theme
                                                                      )['Text'],
                                                                      {
                                                                        color: [
                                                                          {
                                                                            minWidth:
                                                                              Breakpoints.Mobile,
                                                                            value:
                                                                              'rgb(255, 255, 255)',
                                                                          },
                                                                          {
                                                                            minWidth:
                                                                              Breakpoints.Mobile,
                                                                            value:
                                                                              getUserData?.text_secondary,
                                                                          },
                                                                        ],
                                                                        fontSize: 12,
                                                                      }
                                                                    ),
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  {
                                                                    itemListData?.description
                                                                  }
                                                                </Text>
                                                              </View>
                                                              {/* Type & Price */}
                                                              <View
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    flexDirection:
                                                                      'row',
                                                                    justifyContent:
                                                                      'space-between',
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {/* Type */}
                                                                <View
                                                                  style={StyleSheet.applyWidth(
                                                                    {
                                                                      flexDirection:
                                                                        'row',
                                                                    },
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  {/* Veg */}
                                                                  <>
                                                                    {!(
                                                                      itemListData?.dietary_type ===
                                                                      'Veg'
                                                                    ) ? null : (
                                                                      <Image
                                                                        resizeMode={
                                                                          'cover'
                                                                        }
                                                                        source={
                                                                          Images.Veg
                                                                        }
                                                                        style={StyleSheet.applyWidth(
                                                                          StyleSheet.compose(
                                                                            GlobalStyles.ImageStyles(
                                                                              theme
                                                                            )[
                                                                            'Image'
                                                                            ],
                                                                            {
                                                                              height: 24,
                                                                              width: 24,
                                                                            }
                                                                          ),
                                                                          dimensions.width
                                                                        )}
                                                                      />
                                                                    )}
                                                                  </>
                                                                  {/* Non-veg */}
                                                                  <>
                                                                    {!(
                                                                      itemListData?.dietary_type ===
                                                                      'Non-veg'
                                                                    ) ? null : (
                                                                      <Image
                                                                        resizeMode={
                                                                          'cover'
                                                                        }
                                                                        source={
                                                                          Images.NonVeg
                                                                        }
                                                                        style={StyleSheet.applyWidth(
                                                                          StyleSheet.compose(
                                                                            GlobalStyles.ImageStyles(
                                                                              theme
                                                                            )[
                                                                            'Image'
                                                                            ],
                                                                            {
                                                                              height: 24,
                                                                              width: 24,
                                                                            }
                                                                          ),
                                                                          dimensions.width
                                                                        )}
                                                                      />
                                                                    )}
                                                                  </>
                                                                  {/* Egg */}
                                                                  <>
                                                                    {!(
                                                                      itemListData?.dietary_type ===
                                                                      'Egg'
                                                                    ) ? null : (
                                                                      <Image
                                                                        resizeMode={
                                                                          'cover'
                                                                        }
                                                                        source={
                                                                          Images.Egg
                                                                        }
                                                                        style={StyleSheet.applyWidth(
                                                                          StyleSheet.compose(
                                                                            GlobalStyles.ImageStyles(
                                                                              theme
                                                                            )[
                                                                            'Image'
                                                                            ],
                                                                            {
                                                                              height: 24,
                                                                              width: 24,
                                                                            }
                                                                          ),
                                                                          dimensions.width
                                                                        )}
                                                                      />
                                                                    )}
                                                                  </>
                                                                  {/* Vegan */}
                                                                  <>
                                                                    {!(
                                                                      itemListData?.dietary_type ===
                                                                      'Vegan'
                                                                    ) ? null : (
                                                                      <Image
                                                                        resizeMode={
                                                                          'cover'
                                                                        }
                                                                        source={
                                                                          Images.Vegan
                                                                        }
                                                                        style={StyleSheet.applyWidth(
                                                                          StyleSheet.compose(
                                                                            GlobalStyles.ImageStyles(
                                                                              theme
                                                                            )[
                                                                            'Image'
                                                                            ],
                                                                            {
                                                                              height: 24,
                                                                              width: 24,
                                                                            }
                                                                          ),
                                                                          dimensions.width
                                                                        )}
                                                                      />
                                                                    )}
                                                                  </>
                                                                </View>
                                                                {/* Price */}
                                                                <View
                                                                  style={StyleSheet.applyWidth(
                                                                    {
                                                                      alignItems:
                                                                        'center',
                                                                      backgroundColor:
                                                                        [
                                                                          {
                                                                            minWidth:
                                                                              Breakpoints.Mobile,
                                                                            value:
                                                                              'rgb(234, 199, 132)',
                                                                          },
                                                                          {
                                                                            minWidth:
                                                                              Breakpoints.Mobile,
                                                                            value:
                                                                              getUserData?.accent_color,
                                                                          },
                                                                        ],
                                                                      borderRadius: 4,
                                                                      height: 24,
                                                                      justifyContent:
                                                                        'center',
                                                                    },
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  <Text
                                                                    accessible={
                                                                      true
                                                                    }
                                                                    allowFontScaling={
                                                                      true
                                                                    }
                                                                    style={StyleSheet.applyWidth(
                                                                      StyleSheet.compose(
                                                                        GlobalStyles.TextStyles(
                                                                          theme
                                                                        )[
                                                                        'Text'
                                                                        ],
                                                                        {
                                                                          color:
                                                                            getUserData?.text_primary,
                                                                          fontFamily:
                                                                            'System',
                                                                          fontWeight:
                                                                            '600',
                                                                          paddingLeft: 4,
                                                                          paddingRight: 4,
                                                                        }
                                                                      ),
                                                                      dimensions.width
                                                                    )}
                                                                  >
                                                                    {
                                                                      getUserData?.currency
                                                                    }
                                                                    {
                                                                      itemListData?.price
                                                                    }
                                                                  </Text>
                                                                </View>
                                                              </View>
                                                            </View>
                                                          </View>
                                                          {/* Options Text */}
                                                          <>
                                                            {!(
                                                              selecteditem ===
                                                              itemListData?.title
                                                            ) ? null : (
                                                              <Text
                                                                accessible={
                                                                  true
                                                                }
                                                                allowFontScaling={
                                                                  true
                                                                }
                                                                style={StyleSheet.applyWidth(
                                                                  StyleSheet.compose(
                                                                    GlobalStyles.TextStyles(
                                                                      theme
                                                                    )['Text'],
                                                                    {
                                                                      color:
                                                                        getUserData?.text_secondary,
                                                                      fontFamily:
                                                                        'System',
                                                                      fontSize: 10,
                                                                      fontWeight:
                                                                        '600',
                                                                      lineHeight: 10,
                                                                      marginBottom: 4,
                                                                      marginLeft: 4,
                                                                      marginTop: 4,
                                                                    }
                                                                  ),
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {'Options'}
                                                              </Text>
                                                            )}
                                                          </>
                                                          {/* Options */}
                                                          <>
                                                            {!(
                                                              selecteditem ===
                                                              itemListData?.title
                                                            ) ? null : (
                                                              <View
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    flexDirection:
                                                                      'row',
                                                                    height: 60,
                                                                    paddingRight: 4,
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {/* Option A */}
                                                                <>
                                                                  {!(
                                                                    itemListData?.option_a_title !==
                                                                    null
                                                                  ) ? null : (
                                                                    <View
                                                                      style={StyleSheet.applyWidth(
                                                                        {
                                                                          backgroundColor:
                                                                            [
                                                                              {
                                                                                minWidth:
                                                                                  Breakpoints.Mobile,
                                                                                value:
                                                                                  'rgb(234, 199, 132)',
                                                                              },
                                                                              {
                                                                                minWidth:
                                                                                  Breakpoints.Mobile,
                                                                                value:
                                                                                  getUserData?.accent_color,
                                                                              },
                                                                            ],
                                                                          borderRadius: 4,
                                                                          flex: 1,
                                                                          marginBottom: 4,
                                                                          marginLeft: 4,
                                                                          padding: 2,
                                                                        },
                                                                        dimensions.width
                                                                      )}
                                                                    >
                                                                      <View
                                                                        style={StyleSheet.applyWidth(
                                                                          {
                                                                            alignItems:
                                                                              'center',
                                                                            backgroundColor:
                                                                              [
                                                                                {
                                                                                  minWidth:
                                                                                    Breakpoints.Mobile,
                                                                                  value:
                                                                                    'rgb(0, 100, 66)',
                                                                                },
                                                                                {
                                                                                  minWidth:
                                                                                    Breakpoints.Mobile,
                                                                                  value:
                                                                                    getUserData?.widget_color,
                                                                                },
                                                                              ],
                                                                            borderTopLeftRadius: 2,
                                                                            borderTopRightRadius: 2,
                                                                            flex: 1,
                                                                            justifyContent:
                                                                              'center',
                                                                          },
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        <Text
                                                                          accessible={
                                                                            true
                                                                          }
                                                                          allowFontScaling={
                                                                            true
                                                                          }
                                                                          style={StyleSheet.applyWidth(
                                                                            StyleSheet.compose(
                                                                              GlobalStyles.TextStyles(
                                                                                theme
                                                                              )[
                                                                              'Text'
                                                                              ],
                                                                              {
                                                                                color:
                                                                                  [
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        'rgb(255, 255, 255)',
                                                                                    },
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        getUserData?.text_secondary,
                                                                                    },
                                                                                  ],
                                                                                fontFamily:
                                                                                  'System',
                                                                                fontWeight:
                                                                                  '600',
                                                                              }
                                                                            ),
                                                                            dimensions.width
                                                                          )}
                                                                        >
                                                                          {
                                                                            itemListData?.option_a_title
                                                                          }
                                                                        </Text>
                                                                      </View>
                                                                      {/* View 2 */}
                                                                      <View
                                                                        style={StyleSheet.applyWidth(
                                                                          {
                                                                            alignItems:
                                                                              'center',
                                                                            flex: 1,
                                                                            justifyContent:
                                                                              'center',
                                                                            paddingTop: 2,
                                                                          },
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        <Text
                                                                          accessible={
                                                                            true
                                                                          }
                                                                          allowFontScaling={
                                                                            true
                                                                          }
                                                                          style={StyleSheet.applyWidth(
                                                                            StyleSheet.compose(
                                                                              GlobalStyles.TextStyles(
                                                                                theme
                                                                              )[
                                                                              'Text'
                                                                              ],
                                                                              {
                                                                                color:
                                                                                  [
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        'rgb(0, 0, 0)',
                                                                                    },
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        getUserData?.text_primary,
                                                                                    },
                                                                                  ],
                                                                                fontFamily:
                                                                                  'System',
                                                                                fontWeight:
                                                                                  '600',
                                                                              }
                                                                            ),
                                                                            dimensions.width
                                                                          )}
                                                                        >
                                                                          {
                                                                            getUserData?.currency
                                                                          }
                                                                          {
                                                                            itemListData?.option_a_price
                                                                          }
                                                                        </Text>
                                                                      </View>
                                                                    </View>
                                                                  )}
                                                                </>
                                                                {/* Option B */}
                                                                <>
                                                                  {!(
                                                                    itemListData?.option_b_title !==
                                                                    null
                                                                  ) ? null : (
                                                                    <View
                                                                      style={StyleSheet.applyWidth(
                                                                        {
                                                                          backgroundColor:
                                                                            [
                                                                              {
                                                                                minWidth:
                                                                                  Breakpoints.Mobile,
                                                                                value:
                                                                                  'rgb(234, 199, 132)',
                                                                              },
                                                                              {
                                                                                minWidth:
                                                                                  Breakpoints.Mobile,
                                                                                value:
                                                                                  getUserData?.accent_color,
                                                                              },
                                                                            ],
                                                                          borderRadius: 4,
                                                                          flex: 1,
                                                                          marginBottom: 4,
                                                                          marginLeft: 4,
                                                                          padding: 2,
                                                                        },
                                                                        dimensions.width
                                                                      )}
                                                                    >
                                                                      <View
                                                                        style={StyleSheet.applyWidth(
                                                                          {
                                                                            alignItems:
                                                                              'center',
                                                                            backgroundColor:
                                                                              [
                                                                                {
                                                                                  minWidth:
                                                                                    Breakpoints.Mobile,
                                                                                  value:
                                                                                    'rgb(0, 100, 66)',
                                                                                },
                                                                                {
                                                                                  minWidth:
                                                                                    Breakpoints.Mobile,
                                                                                  value:
                                                                                    getUserData?.widget_color,
                                                                                },
                                                                              ],
                                                                            borderTopLeftRadius: 2,
                                                                            borderTopRightRadius: 2,
                                                                            flex: 1,
                                                                            justifyContent:
                                                                              'center',
                                                                          },
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        <Text
                                                                          accessible={
                                                                            true
                                                                          }
                                                                          allowFontScaling={
                                                                            true
                                                                          }
                                                                          style={StyleSheet.applyWidth(
                                                                            StyleSheet.compose(
                                                                              GlobalStyles.TextStyles(
                                                                                theme
                                                                              )[
                                                                              'Text'
                                                                              ],
                                                                              {
                                                                                color:
                                                                                  [
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        'rgb(255, 255, 255)',
                                                                                    },
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        getUserData?.text_secondary,
                                                                                    },
                                                                                  ],
                                                                                fontFamily:
                                                                                  'System',
                                                                                fontWeight:
                                                                                  '600',
                                                                              }
                                                                            ),
                                                                            dimensions.width
                                                                          )}
                                                                        >
                                                                          {
                                                                            itemListData?.option_b_title
                                                                          }
                                                                        </Text>
                                                                      </View>
                                                                      {/* View 2 */}
                                                                      <View
                                                                        style={StyleSheet.applyWidth(
                                                                          {
                                                                            alignItems:
                                                                              'center',
                                                                            flex: 1,
                                                                            justifyContent:
                                                                              'center',
                                                                            paddingTop: 2,
                                                                          },
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        <Text
                                                                          accessible={
                                                                            true
                                                                          }
                                                                          allowFontScaling={
                                                                            true
                                                                          }
                                                                          style={StyleSheet.applyWidth(
                                                                            StyleSheet.compose(
                                                                              GlobalStyles.TextStyles(
                                                                                theme
                                                                              )[
                                                                              'Text'
                                                                              ],
                                                                              {
                                                                                color:
                                                                                  [
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        'rgb(0, 0, 0)',
                                                                                    },
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        getUserData?.text_primary,
                                                                                    },
                                                                                  ],
                                                                                fontFamily:
                                                                                  'System',
                                                                                fontWeight:
                                                                                  '600',
                                                                              }
                                                                            ),
                                                                            dimensions.width
                                                                          )}
                                                                        >
                                                                          {
                                                                            getUserData?.currency
                                                                          }
                                                                          {
                                                                            itemListData?.option_b_price
                                                                          }
                                                                        </Text>
                                                                      </View>
                                                                    </View>
                                                                  )}
                                                                </>
                                                                {/* Option C */}
                                                                <>
                                                                  {!(
                                                                    itemListData?.option_c_title !==
                                                                    null
                                                                  ) ? null : (
                                                                    <View
                                                                      style={StyleSheet.applyWidth(
                                                                        {
                                                                          backgroundColor:
                                                                            [
                                                                              {
                                                                                minWidth:
                                                                                  Breakpoints.Mobile,
                                                                                value:
                                                                                  'rgb(234, 199, 132)',
                                                                              },
                                                                              {
                                                                                minWidth:
                                                                                  Breakpoints.Mobile,
                                                                                value:
                                                                                  getUserData?.accent_color,
                                                                              },
                                                                            ],
                                                                          borderRadius: 4,
                                                                          flex: 1,
                                                                          marginBottom: 4,
                                                                          marginLeft: 4,
                                                                          padding: 2,
                                                                        },
                                                                        dimensions.width
                                                                      )}
                                                                    >
                                                                      <View
                                                                        style={StyleSheet.applyWidth(
                                                                          {
                                                                            alignItems:
                                                                              'center',
                                                                            backgroundColor:
                                                                              [
                                                                                {
                                                                                  minWidth:
                                                                                    Breakpoints.Mobile,
                                                                                  value:
                                                                                    'rgb(0, 100, 66)',
                                                                                },
                                                                                {
                                                                                  minWidth:
                                                                                    Breakpoints.Mobile,
                                                                                  value:
                                                                                    getUserData?.widget_color,
                                                                                },
                                                                              ],
                                                                            borderTopLeftRadius: 2,
                                                                            borderTopRightRadius: 2,
                                                                            flex: 1,
                                                                            justifyContent:
                                                                              'center',
                                                                          },
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        <Text
                                                                          accessible={
                                                                            true
                                                                          }
                                                                          allowFontScaling={
                                                                            true
                                                                          }
                                                                          style={StyleSheet.applyWidth(
                                                                            StyleSheet.compose(
                                                                              GlobalStyles.TextStyles(
                                                                                theme
                                                                              )[
                                                                              'Text'
                                                                              ],
                                                                              {
                                                                                color:
                                                                                  [
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        'rgb(255, 255, 255)',
                                                                                    },
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        getUserData?.text_secondary,
                                                                                    },
                                                                                  ],
                                                                                fontFamily:
                                                                                  'System',
                                                                                fontWeight:
                                                                                  '600',
                                                                              }
                                                                            ),
                                                                            dimensions.width
                                                                          )}
                                                                        >
                                                                          {
                                                                            itemListData?.option_c_title
                                                                          }
                                                                        </Text>
                                                                      </View>
                                                                      {/* View 2 */}
                                                                      <View
                                                                        style={StyleSheet.applyWidth(
                                                                          {
                                                                            alignItems:
                                                                              'center',
                                                                            flex: 1,
                                                                            justifyContent:
                                                                              'center',
                                                                            paddingTop: 2,
                                                                          },
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        <Text
                                                                          accessible={
                                                                            true
                                                                          }
                                                                          allowFontScaling={
                                                                            true
                                                                          }
                                                                          style={StyleSheet.applyWidth(
                                                                            StyleSheet.compose(
                                                                              GlobalStyles.TextStyles(
                                                                                theme
                                                                              )[
                                                                              'Text'
                                                                              ],
                                                                              {
                                                                                color:
                                                                                  [
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        'rgb(0, 0, 0)',
                                                                                    },
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        getUserData?.text_primary,
                                                                                    },
                                                                                  ],
                                                                                fontFamily:
                                                                                  'System',
                                                                                fontWeight:
                                                                                  '600',
                                                                              }
                                                                            ),
                                                                            dimensions.width
                                                                          )}
                                                                        >
                                                                          {
                                                                            getUserData?.currency
                                                                          }
                                                                          {
                                                                            itemListData?.option_c_price
                                                                          }
                                                                        </Text>
                                                                      </View>
                                                                    </View>
                                                                  )}
                                                                </>
                                                                {/* Option D */}
                                                                <>
                                                                  {!(
                                                                    itemListData?.option_d_title !==
                                                                    null
                                                                  ) ? null : (
                                                                    <View
                                                                      style={StyleSheet.applyWidth(
                                                                        {
                                                                          backgroundColor:
                                                                            [
                                                                              {
                                                                                minWidth:
                                                                                  Breakpoints.Mobile,
                                                                                value:
                                                                                  'rgb(234, 199, 132)',
                                                                              },
                                                                              {
                                                                                minWidth:
                                                                                  Breakpoints.Mobile,
                                                                                value:
                                                                                  getUserData?.accent_color,
                                                                              },
                                                                            ],
                                                                          borderRadius: 4,
                                                                          flex: 1,
                                                                          marginBottom: 4,
                                                                          marginLeft: 4,
                                                                          padding: 2,
                                                                        },
                                                                        dimensions.width
                                                                      )}
                                                                    >
                                                                      <View
                                                                        style={StyleSheet.applyWidth(
                                                                          {
                                                                            alignItems:
                                                                              'center',
                                                                            backgroundColor:
                                                                              [
                                                                                {
                                                                                  minWidth:
                                                                                    Breakpoints.Mobile,
                                                                                  value:
                                                                                    'rgb(0, 100, 66)',
                                                                                },
                                                                                {
                                                                                  minWidth:
                                                                                    Breakpoints.Mobile,
                                                                                  value:
                                                                                    getUserData?.widget_color,
                                                                                },
                                                                              ],
                                                                            borderTopLeftRadius: 2,
                                                                            borderTopRightRadius: 2,
                                                                            flex: 1,
                                                                            justifyContent:
                                                                              'center',
                                                                          },
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        <Text
                                                                          accessible={
                                                                            true
                                                                          }
                                                                          allowFontScaling={
                                                                            true
                                                                          }
                                                                          style={StyleSheet.applyWidth(
                                                                            StyleSheet.compose(
                                                                              GlobalStyles.TextStyles(
                                                                                theme
                                                                              )[
                                                                              'Text'
                                                                              ],
                                                                              {
                                                                                color:
                                                                                  [
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        'rgb(255, 255, 255)',
                                                                                    },
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        getUserData?.text_secondary,
                                                                                    },
                                                                                  ],
                                                                                fontFamily:
                                                                                  'System',
                                                                                fontWeight:
                                                                                  '600',
                                                                              }
                                                                            ),
                                                                            dimensions.width
                                                                          )}
                                                                        >
                                                                          {
                                                                            itemListData?.option_d_title
                                                                          }
                                                                        </Text>
                                                                      </View>
                                                                      {/* View 2 */}
                                                                      <View
                                                                        style={StyleSheet.applyWidth(
                                                                          {
                                                                            alignItems:
                                                                              'center',
                                                                            flex: 1,
                                                                            justifyContent:
                                                                              'center',
                                                                            paddingTop: 2,
                                                                          },
                                                                          dimensions.width
                                                                        )}
                                                                      >
                                                                        <Text
                                                                          accessible={
                                                                            true
                                                                          }
                                                                          allowFontScaling={
                                                                            true
                                                                          }
                                                                          style={StyleSheet.applyWidth(
                                                                            StyleSheet.compose(
                                                                              GlobalStyles.TextStyles(
                                                                                theme
                                                                              )[
                                                                              'Text'
                                                                              ],
                                                                              {
                                                                                color:
                                                                                  [
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        'rgb(0, 0, 0)',
                                                                                    },
                                                                                    {
                                                                                      minWidth:
                                                                                        Breakpoints.Mobile,
                                                                                      value:
                                                                                        getUserData?.text_primary,
                                                                                    },
                                                                                  ],
                                                                                fontFamily:
                                                                                  'System',
                                                                                fontWeight:
                                                                                  '600',
                                                                              }
                                                                            ),
                                                                            dimensions.width
                                                                          )}
                                                                        >
                                                                          {
                                                                            getUserData?.currency
                                                                          }
                                                                          {
                                                                            itemListData?.option_d_price
                                                                          }
                                                                        </Text>
                                                                      </View>
                                                                    </View>
                                                                  )}
                                                                </>
                                                              </View>
                                                            )}
                                                          </>
                                                        </View>
                                                      </Touchable>
                                                    )}
                                                  </>
                                                );
                                              }}
                                              showsHorizontalScrollIndicator={
                                                false
                                              }
                                              showsVerticalScrollIndicator={
                                                false
                                              }
                                            />
                                          </>
                                        );
                                      }}
                                    </EmenApi.FetchGetItemsGET>
                                  )}
                                </>
                              </View>
                            </View>
                          </View>
                        </>
                      );
                    }}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                  />
                </>
              );
            }}
          </EmenApi.FetchGetUserGET>
        )}
      </>
      <>
        {!((props.route?.params?.username ?? null) === null) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                height: '100%',
                justifyContent: 'center',
                width: '100%',
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  Linking.openURL('https://emenufy.in');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={Images.CreateYourEmenufy}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderRadius: 8,
                    height: 120,
                    width: 300,
                  }),
                  dimensions.width
                )}
              />
            </Touchable>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(EmenufyScreen);
