import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const getCategoriesGET = (Constants, { userID }, handlers = {}) =>
  fetch(
    `https://fdfjnmtpkkbtcaspajjq.supabase.co/rest/v1/Categories?select=${encodeURIComponent(
      `*`
    )}&user_id=${encodeURIComponent(
      `eq.${typeof userID === 'string' ? userID : JSON.stringify(userID ?? '')}`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZkZmpubXRwa2tidGNhc3BhampxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODcwODY1NTQsImV4cCI6MjAwMjY2MjU1NH0.8jp1mBqmRttS0PR7aJWBrOewPyT9TUPH6WdSWtWw8ao',
        'Content-Type': 'application/json',
        apiKey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZkZmpubXRwa2tidGNhc3BhampxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODcwODY1NTQsImV4cCI6MjAwMjY2MjU1NH0.8jp1mBqmRttS0PR7aJWBrOewPyT9TUPH6WdSWtWw8ao',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetCategoriesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['categories', args],
    () => getCategoriesGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetCategoriesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  userID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetCategoriesGET(
    { userID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetCategories: refetch });
};

export const getItemsGET = (Constants, { userID }, handlers = {}) =>
  fetch(
    `https://fdfjnmtpkkbtcaspajjq.supabase.co/rest/v1/Items?select=${encodeURIComponent(
      `*`
    )}&user_id=${encodeURIComponent(
      `eq.${typeof userID === 'string' ? userID : JSON.stringify(userID ?? '')}`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZkZmpubXRwa2tidGNhc3BhampxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODcwODY1NTQsImV4cCI6MjAwMjY2MjU1NH0.8jp1mBqmRttS0PR7aJWBrOewPyT9TUPH6WdSWtWw8ao',
        'Content-Type': 'application/json',
        apiKey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZkZmpubXRwa2tidGNhc3BhampxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODcwODY1NTQsImV4cCI6MjAwMjY2MjU1NH0.8jp1mBqmRttS0PR7aJWBrOewPyT9TUPH6WdSWtWw8ao',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetItemsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['items', args],
    () => getItemsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetItemsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  userID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetItemsGET(
    { userID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetItems: refetch });
};

export const getItemsByCategoryGET = (
  Constants,
  { category, userID },
  handlers = {}
) =>
  fetch(
    `https://fdfjnmtpkkbtcaspajjq.supabase.co/rest/v1/Items?select=${encodeURIComponent(
      `*`
    )}&user_id=${encodeURIComponent(
      `eq.${typeof userID === 'string' ? userID : JSON.stringify(userID ?? '')}`
    )}&category=${encodeURIComponent(
      `eq.${
        typeof category === 'string' ? category : JSON.stringify(category ?? '')
      }`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZkZmpubXRwa2tidGNhc3BhampxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODcwODY1NTQsImV4cCI6MjAwMjY2MjU1NH0.8jp1mBqmRttS0PR7aJWBrOewPyT9TUPH6WdSWtWw8ao',
        'Content-Type': 'application/json',
        apiKey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZkZmpubXRwa2tidGNhc3BhampxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODcwODY1NTQsImV4cCI6MjAwMjY2MjU1NH0.8jp1mBqmRttS0PR7aJWBrOewPyT9TUPH6WdSWtWw8ao',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetItemsByCategoryGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['items', args],
    () => getItemsByCategoryGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetItemsByCategoryGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  category,
  userID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetItemsByCategoryGET(
    { category, userID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetItemsByCategory: refetch });
};

export const getOfferGET = (Constants, { userid }, handlers = {}) =>
  fetch(
    `https://fdfjnmtpkkbtcaspajjq.supabase.co/rest/v1/offer?select=${encodeURIComponent(
      `*`
    )}&user_id=${encodeURIComponent(
      `eq.${typeof userid === 'string' ? userid : JSON.stringify(userid ?? '')}`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZkZmpubXRwa2tidGNhc3BhampxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODcwODY1NTQsImV4cCI6MjAwMjY2MjU1NH0.8jp1mBqmRttS0PR7aJWBrOewPyT9TUPH6WdSWtWw8ao',
        'Content-Type': 'application/json',
        apiKey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZkZmpubXRwa2tidGNhc3BhampxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODcwODY1NTQsImV4cCI6MjAwMjY2MjU1NH0.8jp1mBqmRttS0PR7aJWBrOewPyT9TUPH6WdSWtWw8ao',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetOfferGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['offer', args],
    () => getOfferGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['offers']),
    }
  );
};

export const FetchGetOfferGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  userid,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetOfferGET(
    { userid },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetOffer: refetch });
};

export const getSocialLinksGET = (Constants, { userid }, handlers = {}) =>
  fetch(
    `https://fdfjnmtpkkbtcaspajjq.supabase.co/rest/v1/socials?select=${encodeURIComponent(
      `*`
    )}&user_id=${encodeURIComponent(
      `eq.${typeof userid === 'string' ? userid : JSON.stringify(userid ?? '')}`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZkZmpubXRwa2tidGNhc3BhampxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODcwODY1NTQsImV4cCI6MjAwMjY2MjU1NH0.8jp1mBqmRttS0PR7aJWBrOewPyT9TUPH6WdSWtWw8ao',
        'Content-Type': 'application/json',
        apiKey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZkZmpubXRwa2tidGNhc3BhampxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODcwODY1NTQsImV4cCI6MjAwMjY2MjU1NH0.8jp1mBqmRttS0PR7aJWBrOewPyT9TUPH6WdSWtWw8ao',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetSocialLinksGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['social', args],
    () => getSocialLinksGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['socials']),
    }
  );
};

export const FetchGetSocialLinksGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  userid,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetSocialLinksGET(
    { userid },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetSocialLinks: refetch });
};

export const getUserGET = (Constants, { name }, handlers = {}) =>
  fetch(
    `https://fdfjnmtpkkbtcaspajjq.supabase.co/rest/v1/users?select=${encodeURIComponent(
      `*`
    )}&username=${encodeURIComponent(
      `eq.${typeof name === 'string' ? name : JSON.stringify(name ?? '')}`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZkZmpubXRwa2tidGNhc3BhampxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODcwODY1NTQsImV4cCI6MjAwMjY2MjU1NH0.8jp1mBqmRttS0PR7aJWBrOewPyT9TUPH6WdSWtWw8ao',
        'Content-Type': 'application/json',
        apiKey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZkZmpubXRwa2tidGNhc3BhampxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODcwODY1NTQsImV4cCI6MjAwMjY2MjU1NH0.8jp1mBqmRttS0PR7aJWBrOewPyT9TUPH6WdSWtWw8ao',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetUserGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(['user', args], () => getUserGET(Constants, args, handlers), {
    refetchInterval,
    onSuccess: () => queryClient.invalidateQueries(['users']),
  });
};

export const FetchGetUserGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  name,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetUserGET(
    { name },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetUser: refetch });
};
