import * as React from 'react';
import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { systemWeights } from 'react-native-typography';
import { Icon, Touchable } from '@draftbit/ui';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import theme from './themes/Draftbit.js';
import LinkingConfiguration from './LinkingConfiguration.js';

import EmenufyScreen from './screens/EmenufyScreen';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

export default function RootAppNavigator() {
  return (
    <NavigationContainer linking={LinkingConfiguration}>
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        <Stack.Screen
          name="EmenufyScreen"
          component={EmenufyScreen}
          options={({ route }) => ({
            animationEnabled: false,
            cardOverlayEnabled: false,
            title: route?.params?.username
          ? `${route.params.username.toUpperCase()} Emenufy`
          : 'Emenufy',
          })}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
