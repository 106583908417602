export default {
  CreateYourEmenufy: require('../assets/images/CreateYourEmenufy.png'),
  Facebook: require('../assets/images/Facebook.png'),
  Instagram: require('../assets/images/Instagram.png'),
  Snapchat: require('../assets/images/Snapchat.png'),
  Swiggy: require('../assets/images/Swiggy.png'),
  Tiktok: require('../assets/images/Tiktok.png'),
  Youtube: require('../assets/images/Youtube.png'),
  Zomato: require('../assets/images/Zomato.png'),
  Ubereats: require('../assets/images/Ubereats.png'),
  Whatsapp: require('../assets/images/Whatsapp.png'),
  Location: require('../assets/images/Location.png'),
  Google: require('../assets/images/Google.png'),
  PhoneNo: require('../assets/images/PhoneNo.png'),
  Veg: require('../assets/images/Veg.png'),
  NonVeg: require('../assets/images/NonVeg.png'),
  Egg: require('../assets/images/Egg.png'),
  Vegan: require('../assets/images/Vegan.png'),
  PoweredByEmenufyCom: require('../assets/images/PoweredByEmenufyCom.png'),
  PoweredByEmenufy: require('../assets/images/PoweredByEmenufy.png'),
};
